import { AxiosError } from 'axios';
import { action, makeAutoObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import { makePersistable } from 'mobx-persist-store';
import { TFunction } from 'i18next';
import {
  ChangePasswordResquest,
  DispatcherRequest,
  ForgottenPasswordRequest,
  SignInRequest,
} from './Payloads/requests';
import {
  DispatcherResponse,
  ForgottenPasswordResponse,
  GetCurrentUserOrganizationalUnitsResponse,
  GetUserParameterValuesResponse,
  SignInResponse,
  SignOutResponse,
} from './Payloads/responses';
import RootStore from './../index';

import {
  API_DEV_DISPATCHER,
  API_GET_CURRENT_USER_AVAILABLE_ORGANIZATIONAL_UNIT_LIST,
  API_HOST,
  API_PROD_DISPATCHER,
  API_SIGNIN,
  API_SIGNOUT,
  PILOT_CLIENT_WEB,
  USER_CHANGE_PASSWORD,
  USER_FORGET_PASSWORD,
  USER_GET_PARAMETER_VALUES,
  USE_PILOT_CLIENT_WEB,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import { ResponseStatus, IdentityProviders } from '90.quickConnect.Models/enums';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { MsalInfos } from '90.quickConnect.Models/models/user/msalConfig';
import { ForgottenPasswordMethodEnum } from '10.quickConnect.app/components/domain/Login/types';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { NamedUserParameterValue } from '90.quickConnect.Models/models';
import { errorHandler } from '80.quickConnect.Core/helpers';
import { OrganizationalUnitViewer } from '90.quickConnect.Models/models/user/organizationalUnitViewer';
import mapOrganizationnalUnits from '90.quickConnect.Models/mappings/organizationnalUnits/mapOrganizationnalUnit';

const defaultSignInResponse: SignInResponse = {
  status: ResponseStatus.Pending,
  authenticationMethod: 1,
  message: '',
  errorCode: '',
  userUPN: '',
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  job: '',
  organizationalUnitName: '',
  organizationalUnitId: '',
  organizationalUnitCode: '',
  phone: '',
  email: '',
  rights: 0,
  customerName: '',
  providerSettings: {
    instance: '',
    domain: '',
    tenantId: '',
    authority: '',
    iOS: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    android: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    web: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    spa: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
  },
  subscribedFeatures: [],
  userParameterValue: [],
  visiblesOrganizationalUnits: [],
};

class LoginStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/LoginStore/index.ts';

  clientHTTP: IClientHTTP;

  signInInfos: SignInResponse = defaultSignInResponse;

  connected = false;

  authenticationMethod: IdentityProviders = IdentityProviders.AzureActiveDirectory;

  isLogging = false;

  // Dans le cas d'une connexion SSO avec l'AAD en utilisant la redirection
  connectByMsal = false;

  // Utilisation du RootStore afin d'appeler les Stores d'identification (LoginMsalStore, etc...);
  RootStore: RootStore;

  isLoggingOut = false;

  logger: CustomLogger;

  resetCodeLoader = false;
  urlToUse: string | undefined = API_HOST;

  currentOuCode = '';

  lastSetOuCode = '';

  userOrganizationnalUnits: OrganizationalUnitViewer[] = [];

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;

    makeAutoObservable(
      this,
      {
        signInInfos: observable,
        authenticationMethod: observable,
        isLogging: observable,
        isLoggingOut: observable,
        connected: observable,
        connectByMsal: observable,
        urlToUse: observable,
        resetCodeLoader: observable,
        currentOuCode: observable,
        lastSetOuCode: observable,
        userOrganizationnalUnits: observable,
        resetStore: action,
        logInAsync: action,
        logOutAsync: action,
        setConnectByMsal: action,
        setSignInInfos: action,
        setAuthenticationMethod: action,
        setIsLogging: action,
        setIsLoggingOut: action,
        setUrlToUse: action,
        setResetCodeLoader: action,
        checkForFeature: action,
        setCurrentOuCode: action,
        reduceOrganizationnalUnits: action,
        findOrganizationalUnitByCode: action,
        getParentCodes: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: storageKey,
      properties: [
        'signInInfos',
        'connected',
        'connectByMsal',
        'urlToUse',
        'userOrganizationnalUnits',
        'lastSetOuCode',
      ],
      storage: window.localStorage,
    }).then(() => {
      this.currentOuCode = this.lastSetOuCode; // charger le dernier code OU dans le uo code courant
    });
    void makePersistable(this, {
      name: storageKey,
      properties: ['currentOuCode'],
      storage: window.sessionStorage,
    });
  }

  resetStore = () => {
    this.setSignInInfos(defaultSignInResponse);
    this.setIsLogging(false);
    this.setIsLoggingOut(false);
    this.setAuthenticationMethod(1);
    this.setConnectByMsal(false);
    this.setUrlToUse(API_HOST);
    this.setResetCodeLoader(false);
    this.setCurrentOuCode('');
    this.setOrganizationalUnits([]);
  };

  checkForFeature = (feature: string): boolean => {
    return this.signInInfos.subscribedFeatures[feature];
  };

  setCurrentOuCode = (ouCode: string) => {
    this.currentOuCode = ouCode;
    this.lastSetOuCode = ouCode;
  };

  setOrganizationalUnits = (ous: OrganizationalUnitViewer[]) => {
    this.userOrganizationnalUnits = ous;
  };

  setConnectByMsal = async (isMsalRedirecting: boolean) => (this.connectByMsal = isMsalRedirecting);

  setResetCodeLoader = (flag: boolean) => {
    this.resetCodeLoader = flag;
  };

  setIsLogging = (isLogging: boolean) => (this.isLogging = isLogging);

  setIsLoggingOut = (isLoggingOut: boolean) => (this.isLoggingOut = isLoggingOut);

  setSignInInfos = (signInInfos: SignInResponse) => {
    this.signInInfos = signInInfos;
    this.connected = signInInfos.isLoggedIn;
    this.setCurrentOuCode(signInInfos.organizationalUnitCode);
    const userOUs: OrganizationalUnitViewer[] = [];
    if (signInInfos.organizationalUnitId) {
      userOUs.push({
        code: signInInfos.organizationalUnitCode,
        id: signInInfos.organizationalUnitId,
        name: signInInfos.organizationalUnitName,
        isRattachment: true,
        logo: '',
        fullName: signInInfos.organizationalUnitName,
        children: [],
      });
    }
    if (signInInfos.visiblesOrganizationalUnits !== undefined && signInInfos.visiblesOrganizationalUnits.length > 0) {
      signInInfos.visiblesOrganizationalUnits.forEach((ou) => {
        userOUs.push(ou);
      });
    }
    this.setOrganizationalUnits(userOUs);
  };

  reduceOrganizationnalUnits = (units: OrganizationalUnitViewer[]): OrganizationalUnitViewer[] => {
    const map = new Map();
    const roots = new Set();

    // Creer un map avec les codes des unités
    units.forEach((unit) => {
      map.set(unit.code, { ...unit, children: [] });
      roots.add(unit.code);
    });

    // Ajouter les enfants aux parents
    units.forEach((unit) => {
      const parts = unit.code.split('\\');
      // Trouver le parent le plus proche qui existe
      for (let i = parts.length - 1; i > 0; i--) {
        const parentCode = parts.slice(0, i).join('\\');
        if (map.has(parentCode)) {
          map.get(parentCode).children.push(map.get(unit.code));
          roots.delete(unit.code);
          break;
        }
      }
    });

    // Retourner les racines
    return Array.from(roots).map((rootCode) => map.get(rootCode));
  };

  findOrganizationalUnitByCode = (
    organizationalUnits: OrganizationalUnitViewer[],
    code: string,
  ): OrganizationalUnitViewer | undefined => {
    for (const ou of organizationalUnits) {
      if (ou.code === code) {
        return ou;
      }
      if (ou.children && ou.children.length > 0) {
        const found = this.findOrganizationalUnitByCode(ou.children, code);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  getParentCodes = (node: OrganizationalUnitViewer, codes: string[] = []): string[] => {
    if (node.code === this.currentOuCode) {
      return codes.concat(node.code);
    }
    if (node.children) {
      for (const child of node.children) {
        const path = this.getParentCodes(child, codes.concat(node.code));
        if (path.includes(this.currentOuCode)) {
          return path;
        }
      }
    }
    return [];
  };

  /**
   * Permet de reset certaines valeurs du login afin de rediriger vers le login lorsqu'une réponse 401 arrive
   *
   * @param {boolean} isConnected
   * @memberof LoginStore
   */
  setIsConnectedFor401HTTPCode = (isConnected: boolean): void => {
    this.signInInfos.isLoggedIn = isConnected;
    this.connected = isConnected;
    this.connectByMsal = isConnected;
  };

  setUrlToUse = (url: string | undefined) => {
    this.urlToUse = url;
  };

  setAuthenticationMethod = (authenticationMethod: IdentityProviders) =>
    (this.authenticationMethod = authenticationMethod);

  verifyFieldUpnAndCgu = ({ userUPN, cgu }: SignInRequest, t: TFunction): boolean => {
    if (userUPN.trim() === '') {
      toast.error(t('qcapp_warning_no_login_entered').toString());
      return false;
    }

    if (!cgu) {
      toast.error(t('qcapp_login_privacy_policy_not_accepted').toString());
      return false;
    }

    return true;
  };

  logInAsync = async (request: SignInRequest, t: TFunction): Promise<void> => {
    this.logger.resetInitDateTimeApp();
    this.setIsLogging(true);
    this.clientHTTP
      .post<SignInResponse>(this.RootStore.CommonStore.chooseBaseUrl(API_SIGNIN), request, {
        withCredentials: true,
      })
      .then(async (response) => {
        if (200 <= response.status && response.status < 300) {
          if (response.data?.authenticationMethod === IdentityProviders.AzureActiveDirectory && !this.connectByMsal) {
            // Utilisation du LoginMsalStore pour séparer le traitement du logIn par Msal
            this.logger.info(
              LoginStore.TAG,
              `[Client Web] - LoginStore.index.ts, logInAsync method: début du traitement de redirection MSAL pour l'utilisateur ${request.userUPN}.`,
            );

            this.setConnectByMsal(true);

            const {
              providerSettings: { spa, instance, domain, tenantId, authority },
              userUPN,
            } = response.data;

            const msalInfos: MsalInfos = {
              instance,
              domain,
              tenantId,
              authority,
              spa,
              userUPN,
            };

            this.RootStore.LoginMsalStore.redirectLogin(msalInfos);
          } else {
            if (USE_PILOT_CLIENT_WEB === 'true') {
              if (response.data.userParameterValue.length > 0) {
                const findClientPilot = response.data.userParameterValue.find((item) => item.name === PILOT_CLIENT_WEB);
                if (findClientPilot && findClientPilot.value === 'true') {
                  // Connexion réussie. On redirige vers la page HOME
                  this.setSignInInfos(response.data);
                  if (this.signInInfos)
                    this.logger.info(
                      LoginStore.TAG,
                      `[Client Web] - LoginStore index.ts logInAsync method: l'utilisateur ${
                        request.userUPN
                      } est authentifié le ${new Date().toISOString()}`,
                    );
                } else {
                  toast.error(t('pilotClienWeb').toString());
                }
              } else {
                toast.error(t('pilotClienWeb').toString());
              }
            } else {
              // Connexion réussie. On redirige vers la page HOME
              this.setSignInInfos(response.data);
              if (this.signInInfos)
                this.logger.info(
                  LoginStore.TAG,
                  `[Client Web] - LoginStore index.ts logInAsync method: l'utilisateur ${
                    request.userUPN
                  } est authentifié le ${new Date().toISOString()}`,
                );
            }
          }
        } else {
          const error = new Error(
            `réponse du serveur KO pour la connexion : ${response.data.status} pour l'utilisateur ${request.userUPN}: Message: ${response.data.message}`,
          );
          errorHandler(LoginStore.TAG, error, 'logInAsync');
        }
      })
      .catch(async (error) => {
        // eslint-disable-next-line
        const errorAxios = error as AxiosError<SignInResponse>;
        if (
          errorAxios?.response?.status !== 404 &&
          errorAxios?.response?.data?.authenticationMethod === IdentityProviders.QuickConnect
        ) {
          this.setAuthenticationMethod(errorAxios.response.data.authenticationMethod);
        } else {
          this.resetStore();
        }

        if (errorAxios?.response?.status === 404 && errorAxios?.response?.data?.errorCode === 'MSG_SQL_USERNOTFOUND') {
          errorAxios.message = `Utilisateur non trouvé: ${request.userUPN}`;
          errorHandler(LoginStore.TAG, errorAxios, 'logInAsync');
          toast.error(t('logInAsync_userNotFound').toString());
        } else if (request.password) {
          const errorMsg = errorAxios?.response
            ? `${errorAxios?.response?.status} ${errorAxios?.response?.statusText}`
            : errorAxios?.message;
          errorAxios.message = `Mauvais mot de passe! Statut: ${errorAxios.response?.data.status} - Message: ${errorAxios.response?.data.message}`;
          errorHandler(LoginStore.TAG, errorAxios, 'logInAsync');

          toast.error(
            t('logInAsync_error_withMsg', {
              msg: errorMsg,
            }).toString(),
          );
        }
      })
      .finally(() => {
        this.setIsLogging(false);
      });
  };

  logOutAsync = async (t: TFunction): Promise<void> => {
    const { userUPN } = this.signInInfos;
    this.logger.info(
      LoginStore.TAG,
      `[Client Web] - LoginStore index.ts logOutAsync method: Début de la déconnexion de l'utilisateur ${userUPN}`,
    );
    // Vérification si l'utilisateur s'est connecté par l'AAD
    if (this.connectByMsal) {
      // On reset certains observables.
      this.RootStore.FormStore.MainMenuStore.reset();
      // On doit loggout l'utilisateur en faisant appel à Microsoft
      await this.RootStore.LoginMsalStore.logoutMsalAsync();
      this.setConnectByMsal(false);
    } else {
      this.setIsLoggingOut(true);
      this.clientHTTP
        .post<SignOutResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_SIGNOUT),
          {},
          {
            withCredentials: true,
          },
        )
        .then(async (response) => {
          if (200 <= response.status && response.status < 300) {
            this.setAuthenticationMethod(IdentityProviders.AzureActiveDirectory);
            await this.logger.info(
              LoginStore.TAG,
              `[Client Web] - LoginStore index.ts logOutAsync method: réponse du serveur ok pour la déconnexion: ${response.status} pour l'utilisateur ${userUPN}`,
            );
          } else
            throw new Error(
              `réponse du serveur KO pour la déconnexion : ${response.data.status} pour l'utilisateur ${userUPN} - Message: ${response.data.message}`,
            );
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            const errorAxios = error as AxiosError<SignOutResponse>;
            const errorMsg = errorAxios?.response
              ? `${errorAxios?.response?.data.status} ${errorAxios?.response?.data.message}`
              : errorAxios?.message;

            errorHandler(LoginStore.TAG, errorAxios, 'logOutAsync');

            toast.error(
              t('logOutAsync_errorWithMsg', {
                msg: errorMsg,
              }).toString(),
            );
          }
        })
        .finally(async () => {
          this.setSignInInfos(defaultSignInResponse);
          this.setIsLoggingOut(false);
          // Supression des données URL enregistrées par le SessionStore
          this.RootStore.SessionStore.resetStore();
          if (!this.signInInfos)
            await this.logger.info(
              LoginStore.TAG,
              `[Client Web] - LoginStore index.ts logOutAsync method: fin d'execution de la méthode pour l'utilisateur ${userUPN}`,
            );
        });
    }
  };

  dispatcherAsync = async (request: DispatcherRequest): Promise<DispatcherResponse | undefined> => {
    try {
      this.logger.resetInitDateTimeApp();
      this.setIsLogging(true);
      const { REACT_APP_ENV, REACT_APP_DEV_KEY_DISPATCHER, REACT_APP_PROD_KEY_DISPATCHER } = process.env;
      const url =
        REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'int' || REACT_APP_ENV === 'demo'
          ? API_DEV_DISPATCHER
          : API_PROD_DISPATCHER;
      const key =
        REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'int' || REACT_APP_ENV === 'demo'
          ? REACT_APP_DEV_KEY_DISPATCHER
          : REACT_APP_PROD_KEY_DISPATCHER;
      const { data } = await this.clientHTTP.post<DispatcherResponse>(`${url}`, request, {
        headers: { 'x-mobile-dispatcher-key': key ?? '' },
      });
      if (data) {
        return data;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosErr = error as AxiosError<DispatcherResponse>;

        axiosErr.message = `[Client Web] DeclarationStore file - dispatcherAsync method: Une erreur est survenue lors de la récupération de l'url par le dispatcher pour cet userUPN ${request.userUpn} : ${axiosErr?.response?.data.status}  ${axiosErr?.response?.data.message}`;
        errorHandler(LoginStore.TAG, error, 'dispatcherAsync');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération de l'url par le dispatcher pour cet userUPN ${request.userUpn} : ${error.message}`;
        errorHandler(LoginStore.TAG, error, 'dispatcherAsync');
      }
    }

    this.setIsLogging(false);
  };

  getAvailableMethods = async (userUpn: string) => {
    this.setResetCodeLoader(true);
    const { data } = await this.clientHTTP.post<ForgottenPasswordResponse>(
      this.RootStore.CommonStore.chooseBaseUrl(USER_FORGET_PASSWORD),
      {
        userUpn,
        appVersion: 67,
        method: ForgottenPasswordMethodEnum.Unknown,
      },
    );
    if (data) {
      this.setResetCodeLoader(false);
      return data;
    }
  };

  getResetCode = async ({ userUpn, method }: ForgottenPasswordRequest, t: TFunction) => {
    try {
      this.setResetCodeLoader(true);
      const { data } = await this.clientHTTP.post<ForgottenPasswordResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(USER_FORGET_PASSWORD),
        {
          userUpn,
          appVersion: 67,
          method,
        },
      );

      if (data) {
        this.setResetCodeLoader(false);
        return data;
      }
    } catch (error) {
      this.setResetCodeLoader(false);
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<any>;
        if (errorAxios?.response?.status === 403) {
          toast.error(errorAxios?.response?.data.message, { autoClose: 10000 });
        } else {
          toast.error(t('forget_password.reset_password_error').toString());
        }
      }
    }
  };

  sendResetCode = async ({ userUpn, newPassword, resetCode }: ForgottenPasswordRequest, t: TFunction) => {
    try {
      this.setResetCodeLoader(true);
      const { data } = await this.clientHTTP.post<ForgottenPasswordResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(USER_FORGET_PASSWORD),
        {
          userUpn,
          appVersion: 67,
          method: ForgottenPasswordMethodEnum.Force,
          newPassword,
          resetCode,
        },
      );

      if (data) {
        this.setResetCodeLoader(false);
        toast.success(t('forget_password.reset_password_success').toString());
        return data;
      }
    } catch (e) {
      this.setResetCodeLoader(false);
      toast.error(t('forget_password.reset_password_error').toString());
    }
  };

  changePassword = async ({ userUPN, newPassword, currentPassword }: ChangePasswordResquest, t: TFunction) => {
    try {
      const { data } = await this.clientHTTP.post(
        this.RootStore.CommonStore.chooseBaseUrl(USER_CHANGE_PASSWORD),
        {
          userUPN,
          newPassword,
          currentPassword,
        },
        {
          withCredentials: true,
        },
      );

      if (data) {
        toast.success(t('qcapp_password_updated').toString());
        return data;
      }
    } catch (e) {
      toast.error(t("The user's password has not been changed").toString());
    }
  };

  async getUserParameterValuesAsync(): Promise<NamedUserParameterValue[]> {
    try {
      const getUserParameterValuesUrl = RootStore.getInstance().CommonStore.chooseBaseUrl(
        `${USER_GET_PARAMETER_VALUES}${this.signInInfos.userUPN}`,
      );
      const {
        data: { status, message, userParameterValues },
      } = await this.clientHTTP.get<GetUserParameterValuesResponse>(getUserParameterValuesUrl, {
        withCredentials: true,
      });

      if (status !== ResponseStatus.OK) throw new Error(`Statut de réponse NO OK: ${message}`);

      return userParameterValues;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetUserParameterValuesResponse>;
        errorAxios.message = `Les infos paramètres utilisateur n'ont pas être récupérées. Statut: ${errorAxios.response?.data.status} - Message: ${errorAxios.response?.data.message}`;
        errorHandler(LoginStore.TAG, error, 'getUserParameterValuesAsync');
      } else if (error instanceof Error) {
        error.message = `Les infos paramètres utilisateur n'ont pas être récupérées. Message: ${error.message}`;
        errorHandler(LoginStore.TAG, error, 'getUserParameterValuesAsync');
      }
    }
    return [];
  }

  getUserOrganizationnalUnits = async (): Promise<void> => {
    try {
      const getUserOrganizationnalUnitsUrl = RootStore.getInstance().CommonStore.chooseBaseUrl(
        `${API_GET_CURRENT_USER_AVAILABLE_ORGANIZATIONAL_UNIT_LIST}`,
      );
      const {
        data: { status, message, organizationalUnits },
      } = await this.clientHTTP.get<GetCurrentUserOrganizationalUnitsResponse>(getUserOrganizationnalUnitsUrl, {
        withCredentials: true,
      });

      if (status !== ResponseStatus.OK) throw new Error(`Statut de réponse NO OK: ${message}`);

      if (organizationalUnits && organizationalUnits.length > 0) {
        const mappedOus = organizationalUnits.map(mapOrganizationnalUnits);
        this.setOrganizationalUnits(mappedOus);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetUserParameterValuesResponse>;
        errorAxios.message = `Les unités organisationnelles n'ont pas être récupérées. Statut: ${errorAxios.response?.data.status} - Message: ${errorAxios.response?.data.message}`;
        errorHandler(LoginStore.TAG, error, 'getUserOrganizationnalUnits');
      } else if (error instanceof Error) {
        error.message = `Les unités organisationnelles n'ont pas être récupérées. Message: ${error.message}`;
        errorHandler(LoginStore.TAG, error, 'getUserOrganizationnalUnits');
      }
    }
  };
}

export default LoginStore;
