import { QCSBool } from './QCSBool';
import { QCSBaseObject } from './QCSBaseObject';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSInt } from './QCSInt';
import { QCSArray } from './QCSArray';
import { QCSChar } from './QCSChar';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';

export class QCSString extends QCSBaseTypedObject<string> {
  public constructor(sValue: string) {
    super(sValue);
    this.value = sValue;
  }

  public add(arg1: QCSBaseObject): QCSString {
    if (arg1 instanceof QCSString) {
      return new QCSString(this.value + arg1.value);
    }

    if (arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null) return new QCSString(this.value);

    const oValue: unknown = arg1?.getValue();
    return new QCSString(`${this.value}${oValue}`);
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 1: // compare
        return new QCSInt(this.compare(qcParams));

      case 9: // isNullOrEmpty
        return new QCSBool(QCSString.isNullOrEmpty(qcParams));

      case 16: // format
        return new QCSString(this.format(qcParams));

      case 21: // join
        return new QCSString(this.join(qcParams));

      default:
        return null;
    }
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 2: // contains
        return new QCSBool(this.contains(qcParams));
      case 3: // startsWith
        return new QCSBool(this.startsWith(qcParams));
      case 4: // endsWith
        return new QCSBool(this.endsWith(qcParams));
      case 5: // indexOf
        return new QCSInt(this.indexOf(qcParams));
      case 6: // lastIndexOf
        return new QCSInt(this.lastIndexOf(qcParams));
      case 7: // length
        return new QCSInt(this.length());
      case 8: // insert
        return new QCSString(this.insert(qcParams));
      case 10: // remove
        return new QCSString(this.remove(qcParams));
      case 11: // replace
        return new QCSString(this.replace(qcParams));
      case 12: // substring
        return new QCSString(this.substring(qcParams));
      case 13: // toLower
        return new QCSString(this.toLower());
      case 14: // toUpper
        return new QCSString(this.toUpper());
      case 15: // trim
        return new QCSString(this.trim(qcParams));
      case 17: // padLeft
        return new QCSString(this.pad(false, qcParams));
      case 18: // padRight
        return new QCSString(this.pad(true, qcParams));
      case 19: // split
        return this.split(qcParams);
      case 20: // compareTo
        return new QCSInt(this.compareTo(qcParams));
      default:
        return null;
    }
  }

  private split(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    const sep: string = (qcParams[0] as QCSString).value;
    const nbMax: number = qcParams.length > 1 ? (qcParams[1] as QCSInt).value : this.value.length;

    let result: QCSArray = QCSArray.empty();
    if (this.value !== null) {
      const sX: string[] = nbMax > 0 ? this.value.split(sep, nbMax) : [this.value];
      const dims: Array<QCSInt> = [new QCSInt(sX.length)];
      result = QCSArray.create(-1, dims);
      for (let i = 0; i < sX.length; i++) {
        const s: string = sX[i];
        result.store(i, new QCSString(s));
      }
    }
    return result;
  }

  private pad(right: boolean, qcParams: Array<QCSBaseObject>): string {
    const totalWidth: number = (qcParams[0] as QCSInt).value;
    if (totalWidth < 1) return this.value;

    const paddingChar: string = qcParams.length > 1 ? (qcParams[1] as QCSChar).value : ' ';
    if (this.value === null || this.value === '') return paddingChar.padStart(totalWidth, paddingChar);
    const len: number = totalWidth - this.value.length;

    if (len < 1) return this.value;

    return right ? this.value.padEnd(totalWidth, paddingChar) : this.value.padStart(totalWidth, paddingChar);
  }

  private static compare(qcParams: Array<QCSBaseObject>): number {
    const s1: QCSString = qcParams[0] as QCSString;
    const s2: QCSString = qcParams[1] as QCSString;
    const ignoreCase: boolean = qcParams.length === 3 ? !qcParams[2].isFalse() : true;

    return ignoreCase
      ? s1.value.toString().toLocaleUpperCase().localeCompare(s2.value.toLocaleUpperCase())
      : QCSString.compareOrdinal(s1.value, s2.value);
  }

  private compareTo(qcParams: Array<QCSBaseObject>): number {
    const s2: QCSString = qcParams[0] as QCSString;
    const ignoreCase: QCSBool = new QCSBool(qcParams.length === 2 ? !qcParams[1].isFalse() : true);

    return QCSString.compare([this, s2, ignoreCase]);
  }

  private contains(qcParams: Array<QCSBaseObject>): boolean {
    if (this.value === null) return false;
    const s1: QCSString = qcParams[0] as QCSString;
    const ignoreCase: boolean = qcParams.length === 2 ? !qcParams[1].isFalse() : true;
    return ignoreCase
      ? this.value.toLocaleUpperCase().includes(s1?.value.toLocaleUpperCase())
      : this.value.includes(s1.value);
  }

  private startsWith(qcParams: Array<QCSBaseObject>): boolean {
    if (this.value === null) return false;
    const s1: QCSString = qcParams[0] as QCSString;
    const ignoreCase: boolean = qcParams.length === 2 ? !qcParams[1].isFalse() : true;

    return ignoreCase
      ? this.value.toLocaleUpperCase().startsWith(s1?.value.toLocaleUpperCase())
      : this.value.startsWith(s1?.value);
  }

  private endsWith(qcParams: Array<QCSBaseObject>): boolean {
    if (this.value === null) return false;
    const s1: QCSString = qcParams[0] as QCSString;
    const ignoreCase: boolean = qcParams.length === 2 ? !qcParams[1].isFalse() : true;

    return ignoreCase
      ? this.value.toLocaleUpperCase().endsWith(s1?.value.toLocaleUpperCase())
      : this.value.endsWith(s1?.value);
  }

  private indexOf(qcParams: Array<QCSBaseObject>): number {
    if (this.value === null) return -1;

    const s1: QCSString = qcParams[0] as QCSString;
    const startIndex: number = qcParams.length >= 2 ? (qcParams[1] instanceof QCSInt ? qcParams[1].value : 0) : 0;
    const ignoreCase: boolean = qcParams.length === 3 ? !qcParams[2].isFalse() : true;

    return ignoreCase
      ? this.value.toLocaleUpperCase().indexOf(s1?.value.toLocaleUpperCase(), startIndex)
      : this.value.indexOf(s1?.value, startIndex);
  }

  private lastIndexOf(qcParams: Array<QCSBaseObject>): number {
    if (this.value === null) return -1;

    const s1: QCSString = qcParams[0] as QCSString;
    const startIndex: number =
      qcParams.length >= 2
        ? qcParams[1] instanceof QCSInt
          ? (qcParams[1] as QCSInt).value
          : this.value.length - 1
        : this.value.length - 1;
    const ignoreCase: boolean = qcParams.length === 3 ? !qcParams[2].isFalse() : true;

    return ignoreCase
      ? this.value.toLocaleUpperCase().lastIndexOf(s1?.value.toLocaleUpperCase(), startIndex)
      : this.value.lastIndexOf(s1?.value);
  }

  private length(): number {
    if (this.value === null) return 0;

    return this.value.length;
  }

  private insert(qcParams: Array<QCSBaseObject>): string {
    const startIndex: number = qcParams[0] instanceof QCSInt ? (qcParams[0] as QCSInt).value : 0;
    const toInsert: string = qcParams[1] instanceof QCSString ? (qcParams[1] as QCSString).value : '';
    if (this.value === null) return toInsert;
    if (startIndex > this.value.length) return this.value + toInsert;
    else if (startIndex < 0) return toInsert + this.value;
    else return this.value.slice(0, startIndex) + toInsert + this.value.slice(startIndex);
  }

  private remove(qcParams: Array<QCSBaseObject>): string {
    if (this.value === null) return '';
    const startIndex: number = qcParams[0] instanceof QCSInt ? qcParams[0].value : 0;
    const nbToRemove: number = qcParams.length > 1 ? (qcParams[1] instanceof QCSInt ? qcParams[1].value : -1) : -1;

    if (startIndex >= this.value.length) return '';

    if (nbToRemove === -1) return this.value.slice(0, startIndex);
    else return this.value.slice(0, startIndex) + this.value.slice(startIndex + nbToRemove);
  }

  private replace(qcParams: Array<QCSBaseObject>): string {
    if (this.value === null) return '';

    const s1: string = qcParams[0] instanceof QCSString ? qcParams[0].value : '';
    const s2: string = qcParams[1] instanceof QCSString ? qcParams[1].value : '';
    const ignoreCase = qcParams.length === 3 ? !qcParams[2].isFalse() : true;
    const flags = ignoreCase ? 'gi' : 'g';
    const pattern = new RegExp(s1, flags);

    return this.value.replace(pattern, s2);
  }

  private substring(qcParams: Array<QCSBaseObject>): string {
    if (this.value === null) return '';

    const startIndex: number = qcParams[0] instanceof QCSInt ? qcParams[0].value : 0;
    const stopIndex: number = qcParams.length === 2 ? (qcParams[1] instanceof QCSInt ? qcParams[1].value : -1) : -1;
    if (stopIndex === -1) return this.value.substring(startIndex);
    else return this.value.substring(startIndex, stopIndex);
  }

  private toLower(): string {
    return this.value?.toLowerCase();
  }

  private toUpper(): string {
    return this.value?.toUpperCase();
  }

  private trim(qcParams: Array<QCSBaseObject>): string {
    if (this.value === null) return '';

    if (qcParams.length === 1) {
      if (qcParams[0] instanceof QCSChar) return StringExtension.trim(this.value, (qcParams[0] as QCSChar).value);
      else if (qcParams[0] instanceof QCSString)
        return StringExtension.trim(this.value, (qcParams[0] as QCSString).value);

      return this.value;
    }

    return this.value.trim();
  }

  private static join(qcParams: Array<QCSBaseObject>): string {
    if (qcParams[0] instanceof QCSString) {
      if (qcParams[1] instanceof QCSArray) {
        let removeEmpty = false;
        if (qcParams.length > 2 && qcParams[2] instanceof QCSBool) removeEmpty = qcParams[2].value;
        const oList: unknown[] = [];
        for (const qcObject of qcParams[1].value) {
          const oValue: unknown =
            qcObject !== QCSBaseObject.QCSNull || qcObject.getValue() !== null ? qcObject.getValue() : null;

          if (removeEmpty && oValue !== undefined) {
            if (oValue === null || (oValue as any).toLocaleString() === null || (oValue as any).toLocaleString() === '')
              continue;
          }
          oList.push(oValue);
        }
        return oList.join(qcParams[0].value);
      }
    }
    return '';
  }

  private static format(qcParams: Array<QCSBaseObject>): string {
    if (qcParams[0] instanceof QCSString) {
      // eslint-disable-next-line
      const sArg1 = qcParams[0];
      const regexPattern = /{(\d+)}/gi;
      const cMatches: RegExpMatchArray | null = sArg1.value.match(regexPattern);

      if (cMatches === null) return '';

      if (!this.checkMatches(cMatches)) return '';
      const arrNb = this.clearCollection(cMatches);
      qcParams.splice(0, 1);
      if (!this.checkParameters(arrNb, qcParams)) return '';
      return this.getResultString(sArg1.value, arrNb, cMatches, qcParams);
    }
    return '';
  }

  private static checkMatches(cMatches: RegExpMatchArray): boolean {
    for (const matche of cMatches) {
      if (!this.isCorrect(matche)) return false;
    }
    return true;
  }

  private static isCorrect(str: string): boolean {
    if (!str.endsWith('}') || !str.startsWith('{')) return false;
    return true;
  }

  private static clearCollection(cMatches: RegExpMatchArray) {
    return cMatches.map((matche: string) => matche.replace('{', '').replace('}', ''));
  }

  private static checkParameters(arrNb: string[], qcParams: Array<QCSBaseObject>): boolean {
    if (qcParams.length !== arrNb.length) {
      return false;
    }
    return true;
  }

  private static getResultString = (
    sArg1: string,
    arrNb: string[],
    cMatches: RegExpMatchArray,
    qcParams: Array<QCSBaseObject>,
  ): string => {
    return sArg1.replace(/{(\d+)}/gi, (matchValue, num) => {
      return typeof qcParams[num] !== undefined ? this.getValueFromObject(qcParams[num]) : matchValue;
    });
  };

  private static getValueFromObject(qcObject: QCSBaseObject): string {
    const oValue: unknown = qcObject.getValue();

    return oValue === null
      ? ''
      : typeof oValue === 'boolean'
      ? QCSString.ucWords(oValue.toString())
      : (oValue as any).toLocaleString();
  }

  private static isNullOrEmpty(qcParams: Array<QCSBaseObject>): boolean {
    const s1: QCSString = qcParams[0] as QCSString;
    return s1.value === null || s1.value === '';
  }

  private static ucWords = (value: string): string =>
    value.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
      return letter.toUpperCase();
    });

  private static compareOrdinal(str1: string, str2: string): number {
    const maxLength = Math.max(str1.length, str2.length);
    let index = 0;
    let result = 0;

    // On compare chaque caractère
    while (index < maxLength) {
      const char1 = str1.at(index);
      const char2 = str2.at(index);

      if (!char1 && char2) {
        result = -1;
        break;
      } else if (char1 && !char2) {
        result = 1;
        break;
      } else if (!char1 && !char2) break;
      else if (char1 !== char2) {
        result = char1! < char2! ? -1 : 1;
        break;
      }
      index += 1;
    }

    return result;
  }

  static from(qcsBaseObject: QCSBaseObject): QCSBaseObject {
    if (qcsBaseObject === null || qcsBaseObject.getValue() === null) return new QCSString('');

    if (typeof qcsBaseObject.getValue() === 'number' || typeof qcsBaseObject.getValue() === 'bigint') {
      const formattedNumericLitteral = numericLocaleFormatLitteral(Number(qcsBaseObject.getValue()));
      return new QCSString(formattedNumericLitteral);
    }

    // eslint-disable-next-line
    return new QCSString((qcsBaseObject.getValue() as any).toLocaleString());
  }
}
