import React, { Suspense, useEffect } from 'react';
import '../index.css';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from 'mobx-react';
import { CssBaseline } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainSkeleton from './MainSkeleton';
import LoginSkeleton from './LoginSkeleton';
import { useHooks } from './hooks';
import { StyledContainer } from './styles';
import RoutesBuilder from '10.quickConnect.app/routes/routesBuilder';
import ErrorBoundary from '10.quickConnect.app/components/shared/ErrorBoundary';

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(window.navigator.language);
  }, [i18n]);
  // eslint-disable-next-line no-empty-pattern
  const { theme } = useHooks();
  const { pathname } = useLocation();
  return (
    <ThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>
        <CssBaseline />

        <Suspense fallback={pathname !== '/' ? <MainSkeleton /> : <LoginSkeleton />}>
          <ErrorBoundary>
            <RoutesBuilder />
            <StyledContainer position="bottom-left" />
          </ErrorBoundary>
        </Suspense>
      </EmotionThemeProvider>
    </ThemeProvider>
  );
};
export default observer(App);
