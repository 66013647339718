import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HOME, LOGIN_ADAL } from './paths';
import { LinkRouteType } from './types';
import { ANONYMOUS_ROUTES, USER_ROUTES } from './routes';
import { useStore } from '30.quickConnect.Stores';

const RoutesBuilder = () => {
  // On récupère le store
  const {
    initQuickConnectApp,
    LoginStore: {
      connected,
      signInInfos: { userUPN },
    },
    DeclarationStore: { findBackUpsByUserUPNAsync },
    SessionStore: { setLastURLSaved },
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // On ajoute des hooks
  useEffect(() => {
    if (!connected && location.pathname !== HOME && location.pathname !== LOGIN_ADAL) {
      // Si on n'est pas connecté on renvoie vers le LOGIN
      if (pathname !== HOME) {
        setLastURLSaved(pathname + location.search);
        navigate(HOME);
      }
    }
  }, [connected, location.pathname, navigate, pathname, setLastURLSaved, location.search]);

  useEffect(() => {
    (async () => {
      if (connected) {
        await initQuickConnectApp();
        // TODO: Voir pour désactiver la rech. dans la sauvegarde auto dans le cas où on passe par le DeepLink
        await findBackUpsByUserUPNAsync(userUPN);
      }
    })();
  }, [connected, initQuickConnectApp, findBackUpsByUserUPNAsync, userUPN]);

  const routesBuilderMemo = useMemo<LinkRouteType[]>(() => (connected ? USER_ROUTES : ANONYMOUS_ROUTES), [connected]);
  const routesBuilder = useRoutes(routesBuilderMemo);
  return routesBuilder;
};
export default observer(RoutesBuilder);
