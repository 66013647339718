import { useNavigate } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { InboxViewer } from '90.quickConnect.Models/models';
import { DECLARATION_FROM_INBOX } from '10.quickConnect.app/routes/paths';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { useStore } from '30.quickConnect.Stores';

const useData = (inboxItem: TreeDataItem): UseDataProps => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const {
    LoginStore: { currentOuCode, setCurrentOuCode, userOrganizationnalUnits },
  } = useStore();
  const { t } = useTranslation('inboxes');

  const { id, formId, activityId, ouCode } = inboxItem as InboxViewer;

  // On ajoute des hooks
  const link = useMemo(
    () => DECLARATION_FROM_INBOX.replace(':formId', formId).replace(':inboxId', id).replace(':activityId', activityId),
    [formId, id, activityId],
  );

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogSubtitle, setDialogSubtitle] = useState<string | null>(null);
  const [ouDeclarationCode, setOUDeclarationCode] = useState<string>('');

  const openDeclaration = useCallback(async () => {
    // Vérifier si l'unité organisationnelle courante correspond à celle de l'inbox
    if (ouCode !== currentOuCode) {
      const ouName = userOrganizationnalUnits.find((ou) => ou.code === ouCode)?.name;
      setDialogSubtitle(`${t('inbox_cannot_open_inbox_change_ou')} (${ouName})`);
      setOUDeclarationCode(ouCode);
      setConfirmDialogOpen(true);
      return;
    }
    navigate(link, { state: { activityId: activityId } });
  }, [activityId, currentOuCode, link, navigate, ouCode, t, userOrganizationnalUnits]);

  const handleConfirmDialogClose = useCallback(() => {
    setConfirmDialogOpen(false);
  }, []);

  const openInNewTab = useCallback(async () => {
    window.open(link);
    setContextMenu(null);
  }, [link]);

  const contextMenuClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleConfirmDialogConfirm = useCallback(
    (ouNewCode: string) => {
      if (ouNewCode !== '') {
        setCurrentOuCode(ouNewCode);
      }
      handleConfirmDialogClose();
      navigate(link, { state: { activityId: activityId } }); // Ouvrir la déclaration après avoir fermé le dialogue
    },
    [link, setCurrentOuCode, handleConfirmDialogClose, navigate, activityId],
  );

  const openContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    },
    [contextMenu],
  );

  return {
    openDeclaration,
    isMobile,
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
    confirmDialogOpen,
    handleConfirmDialogClose,
    handleConfirmDialogConfirm,
    dialogSubtitle,
    ouDeclarationCode,
  };
};

export default useData;
