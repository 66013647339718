import { FieldDesc } from '90.quickConnect.Models/models';

const updateVisibilityFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  isVisible: boolean,
): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId == fullPathId && field.isVisible !== isVisible) {
    changeMade = true;
    field.isVisible = isVisible;
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateVisibilityFieldOrChild(fullPathId, child, isVisible);
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateVisibilityFieldOrChild;
