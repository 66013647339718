import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { QCActionValue, SearchedList } from './types';
import { useStore } from '30.quickConnect.Stores';
import {
  AllFieldValueTypes,
  Choice,
  DeclarationContext,
  DialogDesc,
  FieldDesc,
  QcActionDesc,
} from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { FieldType } from '90.quickConnect.Models/enums';
import { useDeclarationContext } from '10.quickConnect.app/components/domain/Declaration/context/declarationContext';
import { getActionParamFromDeclaration } from '50.quickConnect.Fields/FieldsTypes/Others/QcAction/utils';

const useDataAction = (
  actionField: QcActionDesc & DialogDesc,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  context: DeclarationContext,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  field: FieldDesc,
  open: string | undefined,
  flattenFields: FieldDesc[],
  handleSubmit: (_event: React.SyntheticEvent<Element, Event>) => void,
) => {
  const {
    DeclarationStore: { actionSearchData },
  } = useStore();
  const { fullPathId, value } = field;
  const [localValueForCheckBox, setLocalValueForCheckBox] = useState<Choice[]>([]);
  const [serachedList, setSearchedList] = useState<SearchedList>();
  const [pagination, setPagination] = useState<{ [key: number]: string }>();
  const [actionToken, setActionToken] = useState<string>('');
  const [localValue, setLocalValue] = useState<string>((value as Choice)?.value ?? '');
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [currentElementToDelete, setCurrentElementToDelete] = useState<string>();
  const [compteur, setCompteur] = useState<number>(0);

  const declaration = useDeclarationContext();

  const initialSearch = useCallback(async () => {
    const body = {
      actionName: actionField.actionName,
      actionParam: {
        values: getActionParamFromDeclaration(declaration, fullPathId),
      },
      actionToken: '',
      formId: context.formId,
    };
    const res = await actionSearchData(body);
    if (res) {
      setActionToken(res.data.actionToken);
      setSearchedList(res.data);
      const nextValue: QCActionValue[] = [
        {
          fieldType: FieldType.CheckBoxList,
          id: actionField.id,
          label: actionField.label ?? '',
          value: res.data.resultList,
        },
      ];

      updateDeclaration(fullPathId, nextValue);
    }
  }, [
    actionField.actionName,
    actionSearchData,
    context.formId,
    declaration,
    updateDeclaration,
    fullPathId,
    actionField.id,
    actionField.label,
  ]);

  useEffect(() => {
    if (actionField?.callOnStart && open && open === fullPathId) {
      initialSearch();
    }
  }, [
    actionField.actionName,
    actionField?.callOnStart,
    actionSearchData,
    context.formId,
    open,
    fullPathId,
    actionField?.isPopup,
    initialSearch,
  ]);

  const updateGlobalState = useCallback(() => {
    const selectedChoice = serachedList?.resultList.find((c) => c.value === localValue);
    let data;

    if (actionField.isMultiSelection) {
      data = [
        {
          fieldType: FieldType.CheckBoxList,
          id: actionField.id,
          label: actionField.label,
          value: localValueForCheckBox,
        },
      ];
    } else {
      data = [
        { fieldType: FieldType.RadioList, id: actionField.id, label: actionField.label, value: [selectedChoice] },
      ];
    }
    if (isDelete) {
      const x = (actionField?.value as QCActionValue[])[0].value.filter((v: any) => currentElementToDelete !== v.value);
      if (x.length === 0) {
        data = undefined;
      } else {
        data = [{ ...(actionField?.value as QCActionValue[])[0], value: x }];
      }
      setLocalValueForCheckBox([]);
      setLocalValue('');
    }
    updateDeclaration(fullPathId, data as any);
    setIsDelete(false);
  }, [
    serachedList?.resultList,
    actionField.isMultiSelection,
    actionField.id,
    actionField.label,
    actionField?.value,
    isDelete,
    updateDeclaration,
    fullPathId,
    localValue,
    localValueForCheckBox,
    currentElementToDelete,
  ]);

  const canSearch = useMemo(
    () => (actionField.items ? !actionField.items.some((f) => f.errors && f.errors?.length > 0) : false),
    [actionField.items],
  );

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateCheckBoxLocalState = useCallback(
    (choice: Choice) => () => {
      const newChecked = [...localValueForCheckBox];
      const currentIndex = localValueForCheckBox.findIndex((c) => c.value === choice.value);
      if (currentIndex === -1) {
        newChecked.push(choice);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setLocalValueForCheckBox(newChecked);
      debouncedUpdateGlobalState();
    },
    [localValueForCheckBox, debouncedUpdateGlobalState],
  );
  const updateLocalState = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, newValue: string | Choice) => {
      setLocalValue(newValue as string);
      debouncedUpdateGlobalState();
      handleSubmit(_event);
    },
    [debouncedUpdateGlobalState, handleSubmit],
  );
  const handleSearch = useCallback(
    async (actionsToken?: string) => {
      const findValueToSearch = actionField.items.filter((it) => it.value);
      if (findValueToSearch) {
        const findVal = findValueToSearch.map((v) => ({
          id: v.id,
          fieldType: v.fieldType,
          label: v.label,
          value: v.value,
        }));
        const fetch = async () => {
          const body = {
            actionName: actionField.actionName,
            actionParam: {
              values: findVal,
            },
            actionToken: actionsToken ?? '',
            formId: context.formId,
          };
          const res = await actionSearchData(body);
          if (res) {
            setActionToken(res.data.actionToken);
            setSearchedList(res.data);
          }
        };
        await fetch();
      }
    },
    [actionField.actionName, actionField.items, actionSearchData, context],
  );
  const handleNext = useCallback(async () => {
    const nextIndex = compteur + 1;
    setPagination({ ...pagination, [nextIndex]: actionToken });
    await handleSearch(actionToken);
    setCompteur(() => compteur + 1);
  }, [actionToken, compteur, handleSearch, pagination]);

  const handlePrevious = useCallback(async () => {
    const previousIndex = compteur - 1;

    await handleSearch(pagination![previousIndex]);
    setCompteur(() => compteur - 1);
  }, [compteur, handleSearch, pagination]);

  const handleDelete = useCallback(
    (currentElem: string) => {
      setLocalValue('');
      setIsDelete(true);
      setCurrentElementToDelete(currentElem);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );
  return {
    handleSearch,
    serachedList,
    handleNext,
    handlePrevious,
    updateLocalState,
    localValue,
    compteur,
    canSearch,
    handleDelete,
    updateCheckBoxLocalState,
    localValueForCheckBox,
    initialSearch,
  };
};
export default useDataAction;
