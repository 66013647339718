import { useState } from 'react';
import { TFunction } from 'i18next';
import { UseDataProps } from './types';

import useEffectOnce from './../../../../../80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';

const useData = (t: TFunction): UseDataProps => {
  // On récupère le store
  const {
    LoginMsalStore: { handleRedirectPromise },
  } = useStore();

  const isLogging = true;

  // On ajoute des hooks
  useEffectOnce(() => {
    (async function () {
      await handleRedirectPromise();
    })();
  });

  return {
    isLogging,
  };
};

export default useData;
