import { isCommaSeparator, splitCommaSeparator, splitPipeSeparator } from '80.quickConnect.Core/helpers';
import { haveSeparator } from '80.quickConnect.Core/helpers/sharedListHelper';
import { Choice } from '90.quickConnect.Models/models';

export const evalChoicesListEqualsString = (choices: Choice[], toCompareString: string): boolean => {
  if (choices.length === 0) return false;

  // Nous devons connaitre si la règle utilise une virgule ou bien un pipe
  const andExclusif = isCommaSeparator(toCompareString);

  const separatorFound = haveSeparator(toCompareString);

  // on splite la chaine de caractères en valeurs à rechercher...
  const toCompareChoiceListValues: string[] = andExclusif
    ? splitCommaSeparator(toCompareString)
    : splitPipeSeparator(toCompareString);

  // Vérifions la taille des 2 tableaux
  if (
    (andExclusif && toCompareChoiceListValues.length !== choices.length) ||
    (!separatorFound && toCompareChoiceListValues.length !== choices.length) ||
    (separatorFound && !andExclusif && choices.length !== 1)
  )
    return false;

  // Comparaison finale
  return andExclusif
    ? toCompareChoiceListValues.every(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label === stringValue || value === stringValue),
      )
    : toCompareChoiceListValues.some(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label === stringValue || value === stringValue),
      );
};

export const evalChoicesListContainsString = (choices: Choice[], toCompareString: string): boolean => {
  if (choices.length === 0) return false;

  // Nous devons connaitre si la règle utilise une virgule ou bien un pipe
  const andExclusif = isCommaSeparator(toCompareString);

  // on splite la chaine de caractères en valeurs à rechercher...
  const toCompareChoiceListValues: string[] = andExclusif
    ? splitCommaSeparator(toCompareString)
    : splitPipeSeparator(toCompareString);

  // Comparaison finale
  return andExclusif
    ? toCompareChoiceListValues.every(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label === stringValue || value === stringValue),
      )
    : toCompareChoiceListValues.some(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label === stringValue || value === stringValue),
      );
};

export const evalChoicesListContainsTextString = (choices: Choice[], toCompareString: string): boolean => {
  if (choices.length === 0) return false;

  if (!haveSeparator(toCompareString)) {
    return !!choices.find(({ value }: Choice) => value.includes(toCompareString));
  }

  const andExclusif = isCommaSeparator(toCompareString);

  // on splite la chaine de caractères en valeurs à rechercher...
  const toCompareChoiceListValues: string[] = andExclusif
    ? splitCommaSeparator(toCompareString)
    : splitPipeSeparator(toCompareString);

  // Comparaison finale
  return andExclusif
    ? toCompareChoiceListValues.every(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label.includes(stringValue) || value.includes(stringValue)),
      )
    : toCompareChoiceListValues.some(
        (stringValue: string) =>
          !!choices.find(({ label, value }: Choice) => label.includes(stringValue) || value.includes(stringValue)),
      );
};
