import Dexie, { Table } from 'dexie';
import { InboxesFilteredSchema } from '40.quickConnect.DataAccess/indexedDb/dbs/queriesTypes/inboxes';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { IQuickConnectIndexedDbError } from '40.quickConnect.DataAccess/indexedDb/dbs/interfaces/iQuickConnectIndexedDbError';
import { errorHandler } from '80.quickConnect.Core/helpers';

class InboxesBd extends Dexie implements IQuickConnectIndexedDbError {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/inboxesDb.ts';

  inboxesFiltered!: Table<InboxesFilteredSchema>;

  constructor() {
    super(new.target.name);
    this.version(1).stores({
      inboxesFiltered: '++userUPN, *activityIds, [userUPN+activityId]',
    });
  }

  async addOrUpdateInboxAsync(activityId: string, userUPN: string, sentAt?: Date): Promise<boolean> {
    try {
      return await this.transaction('rw', this.inboxesFiltered, async () => {
        const inboxForThisUserUPN = await this.inboxesFiltered.where({ userUPN, activityId }).first();

        const newInboxSchema = inboxForThisUserUPN
          ? {
              activityIds:
                inboxForThisUserUPN.activityIds.findIndex((activity: string) => activityId === activity) !== -1
                  ? [...inboxForThisUserUPN.activityIds, activityId]
                  : inboxForThisUserUPN.activityIds,
              userUPN,
              sentAt: sentAt ?? inboxForThisUserUPN.sentAt,
              purgeDate: DateTimeExtension.getTomorrowDate(),
            }
          : {
              activityIds: [activityId],
              userUPN,
              sentAt: sentAt ?? new Date(),
              purgeDate: DateTimeExtension.getTomorrowDate(),
            };

        return (await this.inboxesFiltered.put(newInboxSchema)) === userUPN;
      });
    } catch (error: unknown) {
      this.handleDbError(error, 'addOrUpdateInboxAsync');

      return false;
    }
  }

  async getActivityIdsAndSentAtDate(userUPN: string): Promise<{ activityIds: string[]; sentAt: Date } | undefined> {
    try {
      const inbox = await this.inboxesFiltered.where('userUPN').equalsIgnoreCase(userUPN).first();
      if (inbox) {
        return { activityIds: inbox.activityIds, sentAt: inbox.sentAt };
      }
    } catch (error: unknown) {
      this.handleDbError(error, 'getActivityIdsAndOriginalSentAtDate');
    }
    return undefined;
  }

  async purgeInboxesAsync(userUPN: string): Promise<boolean> {
    try {
      const inboxesDeleted = await this.inboxesFiltered
        .where('userUPN')
        .equalsIgnoreCase(userUPN)
        .filter(({ purgeDate }: InboxesFilteredSchema) => !DateTimeExtension.lessThanOneDay(purgeDate, new Date()))
        .delete();

      CustomLogger.getInstance().info(
        InboxesBd.TAG,
        `[Client Web] inboxesDb.ts - purgeInboxesAsync method: Nombre d'inboxes supprimés: ${inboxesDeleted.toString()}`,
      );
      return true;
    } catch (error: unknown) {
      this.handleDbError(error, 'purgeInboxesAsync');

      return false;
    }
  }

  handleDbError(error: unknown, method: string) {
    errorHandler(InboxesBd.TAG, error, method);
  }
}

const inboxesDb = new InboxesBd();

export default inboxesDb;
