import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UseDataProps } from './types';
import { HOME } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';

const useData = (showLeftMenuButton: boolean | undefined): UseDataProps => {
  // On récupère le store
  const {
    CommonStore: { setShowLeftMenu, showLeftMenu },
    DeclarationStore: {
      isUseDeclarationComponent: openSaveDraftDeclarationDialog,
      openDialog,
      editableDeclaration,
      isEditingCurrentDeclaration,
      setIsEditingCurrentDeclaration,
    },
  } = useStore();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path, { replace: false });
  };

  const goHome = useCallback((): void => {
    if (openSaveDraftDeclarationDialog && editableDeclaration) {
      openDialog();
    } else {
      if (isEditingCurrentDeclaration) setIsEditingCurrentDeclaration(false);
      navigate(HOME);
    }
  }, [
    navigate,
    openSaveDraftDeclarationDialog,
    openDialog,
    editableDeclaration,
    setIsEditingCurrentDeclaration,
    isEditingCurrentDeclaration,
  ]);

  useEffect(() => {
    if (!showLeftMenuButton) setShowLeftMenu(false);
  }, [setShowLeftMenu, showLeftMenuButton]);

  return {
    handleNavigation,
    goHome,
    setShowLeftMenu,
    showLeftMenu,
  };
};

export default useData;
