// https://dev.api.quickconnect.fr/api/
export const API_HOST = process.env.REACT_APP_QC_API_ENDPOINT;

// https://dev.api.quickconnect.fr/api/Account/
export const API_ACCOUNT = `${API_HOST}Account/`;

// https://dev.api.quickconnect.fr/api/Account/signin
export const API_SIGNIN = `${API_ACCOUNT}signin`;

// https://dev.api.quickconnect.fr/api/Account/validateToken
export const API_VALIDATE_TOKEN = `${API_ACCOUNT}validateToken`;

// https://dev.api.quickconnect.fr/api/Account/signout
export const API_SIGNOUT = `${API_ACCOUNT}signout`;

// https://dev.api.quickconnect.fr/api/Mobile/
export const API_MOBILE = `${API_HOST}Mobile/`;

// https://dev.api.quickconnect.fr/api/Mobile/synchronizerepository
export const API_SYNCHRONIZE_REPOSITORY = `${API_MOBILE}synchronizerepository`;

// https://dev.api.quickconnect.fr/api/Mobile/synchronizedeclaration
export const API_SYNCHRONIZE_DECLARATION = `${API_MOBILE}synchronizedeclaration`;

// https://dev.api.quickconnect.fr/api/Mobile/synchronizeequipement
export const API_SYNCHRONIZE_EQUIPMENT = `${API_MOBILE}synchronizeequipment`;

// https://dev.api.quickconnect.fr/api/Mobile/synchronizeequipements
export const API_SYNCHRONIZE_EQUIPMENTS = `${API_MOBILE}synchronizeequipments`;

// App
// https://dev.api.quickconnect.fr/api/app/
export const API_APP = `${API_HOST}app/`;

// https://dev.api.quickconnect.fr/api/app/getformviewer
export const API_GET_FORM_LIST = `${API_APP}getformviewer/:forThisUPN`;

// https://dev.api.quickconnect.fr/api/app/getinboxviewer
export const API_GET_INBOXES = `${API_APP}getinboxviewer/:forThisUPN`;

// https://dev.api.quickconnect.fr/api/app/getrundeclarationviewer
export const API_GET_DECLARATIONS = `${API_APP}getrundeclarationviewer/:forThisUPN`;

// https://dev.api.quickconnect.fr/api/app/getinboxcontext/:inboxId
export const API_GET_INBOX_CONTEXT = `${API_APP}getinboxcontext/:inboxId`;

// https://dev.api.quickconnect.fr/api/app/getsharedlists
export const API_GET_SHARED_LISTS = `${API_APP}getsharedlists`;

// Nouvelles API pour la création, la récupération et la duplication d'une déclaration

// https://dev.api.quickconnect.fr/api/Declaration/
export const API_DECLARATION = `${API_HOST}Declaration/`;

// https://dev.api.quickconnect.fr/api/Declaration/deletedeclarationsdrafts
export const API_DELETE_DECLARATION_DRAFTS = `${API_DECLARATION}deletedeclarationsdrafts`;

// Pour la création d'une déclaration

// https://dev.api.quickconnect.fr/api/app/getformcontext/:formId
export const API_GET_FORM_CONTEXT = `${API_APP}getformcontext/:formId`;

// Pour la création d'une déclaration en mode deep link

//https://dev.api.quickconnect.fr/api/app/getformcontextdeeplink/:formName/:exchangeId
export const API_GET_FORM_CONTEXT_DEEP_LINK = `${API_APP}getformcontextdeeplink/:formName/:exchangeId`;

// Pour la récupération d'une déclaration

// https://dev.api.quickconnect.fr/api/app/getdeclarationcontext/:declarationId
export const API_GET_DECLARATION_CONTEXT = `${API_APP}getdeclarationcontext/:declarationId`;

//Pour la duplication d'une déclaration

// https://dev.api.quickconnect.fr/api/app/duplicatedeclarationcontext/:duplicateDeclarationId
export const API_GET_DUPLICATE_DECLARATION_CONTEXT = `${API_APP}duplicatedeclarationcontext/:duplicateDeclarationId`;

// Document

// https://dev.api.quickconnect.fr/api/storage/
export const API_DOCUMENT = `${API_HOST}storage/`;

// https://dev.api.quickconnect.fr/api/storage/StoreArrayIndexes
export const API_GET_DOCUMENT_INDEX = `${API_DOCUMENT}StoreArrayIndexes`;

// Form

// https://dev.api.quickconnect.fr/api/form/
export const API_FORM = `${API_HOST}form/`;

// https://dev.api.quickconnect.fr/api/form/getform
export const API_GET_FORM = `${API_FORM}getform`;

// Shared List

// https://dev.api.quickconnect.fr/api/sharedList
export const API_SHARED_LIST = `${API_HOST}sharedList/`;

// User Param

// https://dev.api.quickconnect.fr/api/user/
export const API_USER = `${API_HOST}user/`;

// https://dev.api.quickconnect.fr/api/user/designuser
export const API_USER_PARAMS = `${API_USER}designuser`;

// https://dev.api.quickconnect.fr/api/User/forgetPassword
export const USER_FORGET_PASSWORD = `${API_HOST}User/forgetpassword`;

// https://dev.api.quickconnect.fr/api/User/changeuserpassword
export const USER_CHANGE_PASSWORD = `${API_HOST}User/changeuserpassword`;

// https://dev.api.quickconnect.fr/api/User/getuserparametervalues/{userUpn}
export const USER_GET_PARAMETER_VALUES = `${API_HOST}User/getuserparametervalues/`;

// https://dev.api.quickconnect.fr/api/OU/getcurrentuseravailableouslist
export const API_GET_CURRENT_USER_AVAILABLE_ORGANIZATIONAL_UNIT_LIST = `${API_HOST}OU/getcurrentuseravailableouslist`;

// Entities

// https://dev.api.quickconnect.fr/api/entities/datasource/getsearchfieldvalues
export const API_GET_SEARCH_FIELD_VALUES = `${API_HOST}entities/datasource/getsearchfieldvalues`;

// AADQuery

// https://dev.api.quickconnect.fr/api/AADQuery/searchuser
export const API_GET_SEARCH_ADD_QUERY = `${API_HOST}AADQuery/searchuser`;

// Instrumentation

// https://dev.api.quickconnect.fr/api/Instrumentation/saveinstrumentation
export const API_POST_INSTRUMENTATION = `${API_HOST}Instrumentation/saveinstrumentation`;

// Historique declarations

// https://dev.api.quickconnect.fr/api/Mobile/searchdeclarationformobile
export const API_POST_HISTORIQUEDECLARATION = `${API_MOBILE}searchdeclarationformobile`;

// informations de l'applications

// https://dev.api.quickconnect.fr/api/information
export const API_GET_API_INFORMATION = `${API_HOST}information`;

// TodoList

// https://dev.api.quickconnect.fr/api/Mobile/downloadtodolist
export const API_GET_TODO_LIST_TASKS = `${API_MOBILE}downloadtodolist`;

// QCAction

// https://dev.api.quickconnect.fr/api/Mobile/action
export const API_POST_QC_ACTION = `${API_HOST}Mobile/action`;

// Dispatcher

// https://dev.dispatcher.quickconnect.fr/api/dispatcher
export const API_DEV_DISPATCHER = process.env.REACT_APP_QC_API_DEV_DISPATCHER;

// https://dispatcher.quickconnect.fr/api/dispatcher
export const API_PROD_DISPATCHER = process.env.REACT_APP_QC_API_PROD_DISPATCHER;

// Constantes

export const PILOT_CLIENT_WEB = process.env.REACT_APP_PILOT_CLIENT_WEB;

export const USE_PILOT_CLIENT_WEB = process.env.REACT_APP_USE_PILOT_CLIENT_WEB;
