import { useCallback, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { ForgottenPasswordMethodEnum, PasswordRulesType, UseDataProps } from './types';
import useEffectOnce from './../../../../80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';
import { ForgottenPasswordRequest, SignInRequest } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/requests';
import {
  DispatcherResponse,
  ForgottenPasswordResponse,
} from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { verifyResetCodeAndPassword } from '80.quickConnect.Core/helpers/verifyForm';
import { errorHandler } from '80.quickConnect.Core/helpers';

const useData = (t: TFunction): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/Login/hooks.ts';
  // On récupère le store
  const {
    LoginStore: {
      logInAsync,
      isLogging,
      setIsLogging,
      authenticationMethod,
      verifyFieldUpnAndCgu,
      connectByMsal,
      signInInfos,
      dispatcherAsync,
      setUrlToUse,
      getAvailableMethods,
      getResetCode,
      resetCodeLoader,
      sendResetCode,
    },
    LoginMsalStore: { handleRedirectPromise },
  } = useStore();

  // On set le state
  const { t: tAxios } = useTranslation('axios');
  const [passwordRules, setPasswordRules] = useState<PasswordRulesType>({
    passLength: false,
    lowerCase: false,
    upperCase: false,
    digit: false,
    specialCharacters: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);
  const [availableMethods, setAvailableMethods] = useState<ForgottenPasswordResponse>();
  const [method, setMethod] = useState<ForgottenPasswordMethodEnum>();
  const [selectMethodPage, setSelectMethodPage] = useState<boolean>(false);
  const [resetPasswordPage, setResetPasswordPage] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] = useState<ForgottenPasswordResponse>();
  const [signInRequest, setsignInRequest] = useState<SignInRequest>({
    userUPN: signInInfos.userUPN,
    password: '',
    cgu: false,
  } as SignInRequest);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [resetCode, setResetCode] = useState<string>('');
  const onForgetPassword = useCallback(async () => {
    try {
      const data = await getAvailableMethods(signInRequest.userUPN);
      if (data) {
        setAvailableMethods(data);
        setForgetPassword(true);
        setSelectMethodPage(true);
      }
    } catch (error: unknown) {
      errorHandler(tag, error, 'onForgetPassword');
    }
  }, [getAvailableMethods, signInRequest, tag]);

  const showPasswordField = useMemo(() => authenticationMethod === 0, [authenticationMethod]);

  const onGoBackToLogin = useCallback(() => {
    setForgetPassword(false);
    setSelectMethodPage(false);
    setResetPasswordPage(false);
  }, []);

  const onResetPassword = useCallback(async () => {
    if (verifyResetCodeAndPassword(false, resetCode, newPassword, confirmPassword, passwordRules, t)) {
      const info: ForgottenPasswordRequest = {
        userUpn: signInRequest.userUPN,
        method: method,
        newPassword,
        resetCode,
      };
      try {
        const data = await sendResetCode(info, t);
        if (data) {
          setForgetPassword(false);
          setSelectMethodPage(false);
          setResetPasswordPage(false);
        }
      } catch (error: unknown) {
        errorHandler(tag, error, 'onResetPassword');
      }
    }
  }, [confirmPassword, method, tag, newPassword, passwordRules, resetCode, sendResetCode, signInRequest.userUPN, t]);

  const onGoToResetPassword = useCallback(() => {
    setResetPasswordPage(true);
    setSelectMethodPage(false);
  }, []);
  const onSelectMethod = useCallback(async () => {
    try {
      const info: ForgottenPasswordRequest = {
        userUpn: signInRequest.userUPN,
        method: method,
      };
      const data = await getResetCode(info, t);
      if (data) {
        setResetPasswordData(data);
        onGoToResetPassword();
      }
    } catch (error: unknown) {
      errorHandler(tag, error, 'onSelectPassword');
    }
  }, [signInRequest.userUPN, method, getResetCode, t, onGoToResetPassword]);

  // On ajoute des hooks
  const setUserUPN = (userUPN: string): void => {
    setsignInRequest((prevState: any) => ({
      ...prevState,
      userUPN,
    }));
  };

  const setCgu = (cgu: boolean): void => {
    setsignInRequest((prevState: any) => ({
      ...prevState,
      cgu,
    }));
  };

  const setPassword = (password: string): void => {
    setsignInRequest((prevState: any) => ({
      ...prevState,
      password,
    }));
  };

  /**
   * Permet de garder le BackDrop open dans le cas d'une connexion par MSAL
   */
  const isOpenBackdrop = useMemo(
    (): boolean => (!isLogging && !connectByMsal ? false : true),
    [isLogging, connectByMsal],
  );

  const connect = useCallback(async (): Promise<void> => {
    if (verifyFieldUpnAndCgu(signInRequest, tAxios)) {
      try {
        if (process.env.REACT_APP_ENV === 'demo') {
          await logInAsync(signInRequest, tAxios);
        } else {
          const result: DispatcherResponse | undefined = await dispatcherAsync({ userUpn: signInRequest.userUPN });
          if (result) {
            if (result.availableEnvironment.length > 1) {
              const customerNames = result.availableEnvironment.map((item) => item.customerName);
              toast.error(
                tAxios('qcapp_warning_error_config_dispatcher_multi_env', { customerName: customerNames }).toString(),
              );
              setIsLogging(false);
            } else if (
              result.availableEnvironment.length == 1 &&
              process.env.REACT_APP_QC_API_ENDPOINT?.search(
                (result.availableEnvironment[0] as any).urlQuickConnect as any,
              ) == -1
            ) {
              // dans le cas où on va changer la base url

              setUrlToUse(`${result.availableEnvironment[0].urlQuickConnect}/api/`);

              await logInAsync(signInRequest, tAxios);
            } else {
              await logInAsync(signInRequest, tAxios);
            }
          }
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          const errorAxios = error as AxiosError<any>;
          if (errorAxios?.response?.status === 404) {
            toast.error(tAxios('logInAsync_userNotFound').toString());
            setIsLogging(false);
          }
        }
      }
    }
  }, [verifyFieldUpnAndCgu, signInRequest, tAxios, dispatcherAsync, setIsLogging, setUrlToUse, logInAsync]);

  useEffectOnce(() => {
    if (connectByMsal) {
      handleRedirectPromise();
    }
  });

  return {
    signInRequest,
    setUserUPN,
    setPassword,
    setCgu,
    connect,
    showPassword,
    setShowPassword,
    isOpenBackdrop,
    isLogging,
    showPasswordField,
    forgetPassword,
    setForgetPassword,
    onForgetPassword,
    availableMethods,
    setMethod,
    method,
    onSelectMethod,
    resetPasswordPage,
    selectMethodPage,
    onGoBackToLogin,
    onGoToResetPassword,
    confirmPassword,
    newPassword,
    setConfirmPassword,
    setNewPassword,
    resetCode,
    setResetCode,
    passwordRules,
    setPasswordRules,
    resetPasswordData,
    onResetPassword,
    resetCodeLoader,
  };
};

export default useData;
