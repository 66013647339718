import React from 'react';
import { observer } from 'mobx-react';
import { Box, Tooltip, Fab, Backdrop, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsName } from './const';
import { CommandsProps } from './types';
import { CommandsContainerStyle } from './styles';
import useData from './hooks';
import PreviousCommand from './PreviousCommand';
import HistoryCommand from './HistoryCommand';
import SendCommand from './SendCommand';
import PauseCommand from './PauseCommand';
import CancelCommand from './CancelCommand';
import TransferCommand from './TransferCommand';
import HistoryBackDrop from './HistoryBackDrop';
import { FormType } from '90.quickConnect.Models/enums';
import ConfirmDialog from '10.quickConnect.app/components/shared/LayOut/ConfirmDialog';
import CancelWorkFlowCommand from '20.formLib/DeclarationContainer/Commands/CancelWorkFlowCommand';

const Commands = ({
  context,
  updateDeclaration,
  declaration,
  canSubmit,
  declarationData,
  setDeclarationData,
  flattenFields,
  steps,
  activityId,
  inboxId,
  declarationId,
  isEditable,
}: CommandsProps) => {
  const { formType } = context;
  const { Workflow } = declarationData;
  const {
    t,
    openCommands,
    setOpenCommands,
    showLeftMenu,
    handleItemDatas,
    sendDeclaration,
    transferDeclaration,
    openHistory,
    setOpenHistory,
    openDeclarationDialog,
    onCloseDialog,
    saveDeclarationInDraft,
    onExitDeclaration,
    onPreviousPage,
  } = useData(context, declaration, declarationData, flattenFields, steps, activityId, inboxId, declarationId);

  const theme = useTheme();

  return (
    <Box data-cy={CommandsName}>
      <Box sx={CommandsContainerStyle(theme, showLeftMenu)}>
        <Tooltip title={t('commands_more').toString()} placement="right">
          <span>
            <Fab
              color="primary"
              data-cy={`${CommandsName}-btn-open`}
              size="large"
              onClick={() => setOpenCommands(true)}
            >
              <DoneIcon />
            </Fab>
          </span>
        </Tooltip>
      </Box>
      <Backdrop open={openCommands} onClick={() => setOpenCommands(false)} sx={{ zIndex: '30' }}>
        <Box sx={CommandsContainerStyle(theme, showLeftMenu)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {formType === FormType.Workflow &&
              Workflow &&
              Workflow.historicalStepDataList &&
              Workflow.historicalStepDataList.length > 0 && (
                <HistoryCommand setOpenHistory={setOpenHistory} setOpenCommands={setOpenCommands} />
              )}
            {isEditable &&
              formType === FormType.Workflow &&
              Workflow &&
              Workflow.historicalStepDataList &&
              Workflow.historicalStepDataList.length > 0 && (
                <PreviousCommand
                  formType={formType}
                  handleItemDatas={handleItemDatas}
                  sendDeclaration={sendDeclaration}
                  setDeclarationData={setDeclarationData}
                />
              )}

            {isEditable &&
              formType === FormType.Workflow &&
              Workflow &&
              Workflow.historicalStepDataList &&
              Workflow.historicalStepDataList.length > 0 && (
                <CancelWorkFlowCommand
                  formType={formType}
                  handleItemDatas={handleItemDatas}
                  sendDeclaration={sendDeclaration}
                  setDeclarationData={setDeclarationData}
                  declarationId={declarationId}
                />
              )}

            {isEditable && (
              <PauseCommand
                context={context}
                declaration={declaration}
                updateDeclaration={updateDeclaration}
                flattenFields={[...flattenFields]}
                handleItemDatas={handleItemDatas}
                setDeclarationData={setDeclarationData}
                activityId={activityId}
                inboxId={inboxId}
                declarationId={declarationId}
              />
            )}

            {isEditable && (
              <TransferCommand
                context={context}
                handleItemDatas={handleItemDatas}
                transferDeclaration={transferDeclaration}
              />
            )}

            {isEditable && (
              <CancelCommand
                formType={formType}
                handleItemDatas={handleItemDatas}
                sendDeclaration={sendDeclaration}
                setDeclarationData={setDeclarationData}
                declarationId={declarationId}
              />
            )}

            {isEditable && (
              <SendCommand
                context={context}
                canSubmit={canSubmit}
                handleItemDatas={handleItemDatas}
                sendDeclaration={sendDeclaration}
                setDeclarationData={setDeclarationData}
                flattenFields={flattenFields}
              />
            )}

            <Tooltip title={t('formlib_close_button_text').toString()} placement="right">
              <Fab
                sx={{ marginTop: '10px' }}
                data-cy={`${CommandsName}-btn-close`}
                color="error"
                size="large"
                onClick={isEditable ? () => setOpenCommands(false) : onPreviousPage}
              >
                <CloseIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Box>
      </Backdrop>
      {formType === FormType.Workflow &&
        Workflow &&
        Workflow.historicalStepDataList &&
        Workflow.historicalStepDataList.length > 0 && (
          <HistoryBackDrop
            openHistory={openHistory}
            setOpenHistory={setOpenHistory}
            historicalStepDataList={Workflow.historicalStepDataList}
          />
        )}
      <ConfirmDialog
        open={openDeclarationDialog}
        close={onCloseDialog}
        actionForLabelOne={saveDeclarationInDraft}
        actionForLabelTwo={onExitDeclaration}
        actionLabelOne={t('qcapp_button_yes').toString()}
        actionLabelTwo={t('qcapp_button_no').toString()}
        cancelLabel={t('qcapp_dialog_action_cancel').toString()}
        title={t('qcapp_alert_dialog_save_form_title').toString()}
        subtitle={t('qcapp_alert_dialog_save_form_message').toString()}
        sendingData={false}
      ></ConfirmDialog>
    </Box>
  );
};

export default observer(Commands);
