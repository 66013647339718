import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, useTheme } from '@mui/material';

import { ListColumnForUserSettingListContainer } from '../styles';
import ForgetPassword from '../../Login/ForgetPassword';
import { CustomInputLabelStyle, CustomTextFieldStyle, TypoStyle } from '../../Login/styles';
import UserProfile from './UserProfile';
import ApplicationInfo from './ApplicationInfos';
import useData from './hooks';
import WithMenu from '10.quickConnect.app/components/shared/LayOut/WithMenu';
import { pureWhiteColor, slateGrey700 } from '00.app/theme';

const UserSetting = () => {
  const theme = useTheme();
  const { t: common } = useTranslation('common');
  const { t: login } = useTranslation('login');

  const {
    confirmPassword,
    newPassword,
    oldPassword,
    onChangePassword,
    openChangePassword,
    passwordRules,
    setConfirmPassword,
    setNewPassword,
    setOldPassword,
    setOpenChangePassword,
    setPasswordRules,
    connectByMsal,
  } = useData();

  return (
    <WithMenu data-cy={'account'} pageTitle={common('qcapp_action_settings')}>
      <Box data-cy={'oldDeclarationName'} sx={ListColumnForUserSettingListContainer(theme)}>
        <UserProfile setOpenChangePassword={setOpenChangePassword} />
        {openChangePassword && !connectByMsal && (
          <Box>
            <form id="login" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
              <CustomInputLabelStyle
                htmlFor="outlined-adornment-old-password"
                sx={{
                  color: theme.palette.mode === 'dark' ? `${pureWhiteColor}!important` : `${slateGrey700}!important`,
                }}
              >{`${login('qcapp_current_password')}`}</CustomInputLabelStyle>
              <FormControl variant="outlined" fullWidth>
                <CustomTextFieldStyle
                  data-cy="password-input"
                  id="outlined-adornment-old-password"
                  type={'password'}
                  required
                  value={oldPassword}
                  onChange={(event: React.ChangeEvent<{ value: string }>) => setOldPassword(event.target.value)}
                />
              </FormControl>

              <ForgetPassword
                setNewPassword={setNewPassword}
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                setPasswordRules={setPasswordRules}
                passwordRules={passwordRules}
                resetPasswordData={undefined}
              />
              <Box mt={2} mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button data-cy="login-btn" variant="contained" type="submit" onClick={onChangePassword}>
                  {common('OK')}
                </Button>
                <Button
                  data-cy="login-btn"
                  variant="contained"
                  type="submit"
                  onClick={() => setOpenChangePassword(false)}
                >
                  {common('ANNULER')}
                </Button>
              </Box>
            </form>
          </Box>
        )}
        <ApplicationInfo />
      </Box>
    </WithMenu>
  );
};
export default UserSetting;
