import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ErrorComponentProps } from './types';
import { Name } from './const';

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation('common');
  return (
    <Box data-cy={Name}>
      <Typography>{t('qcapp_common_errors_label')}</Typography>
      <Typography>{error}</Typography>
    </Box>
  );
};

export default ErrorComponent;
