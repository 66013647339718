import { useCallback, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  QcsFunc,
  UseFormEventQCScriptProps,
} from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/types';
import { AllFieldValueTypes, DeclarationContext, FieldDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

import { InternalFieldState, QCFormEvent } from '90.quickConnect.Models/enums';
import { QCSBool } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBool';
import { QCSFormContext } from '20.formLib/helpers/QCScriptLib/bridge/QCSFormContext';
import { formContextReducer } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/reducers';
import { FormContextAction } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/actionType/enums';
import { CaseInsensitiveMap } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/CaseInsensitiveMap';
import { QCSFunctions } from '20.formLib/DeclarationContainer/contexts/types';

const useFormEventQCScript = (): UseFormEventQCScriptProps => {
  const {
    QCScriptStore: {
      initialize,
      callInterpreter,
      getFullPathId,
      updateFieldValues,
      qci,
      setQCSBaseFieldId,
      setQCSFieldId,
    },
    DeclarationStore: { editableDeclaration, registerNewState },
  } = useStore();

  const qcsFunc = useRef<QcsFunc | undefined>();

  const [fcState, fcDispatch] = useReducer(formContextReducer, null);

  const { t } = useTranslation('declaration');

  const isQCScriptForm: boolean = useMemo(() => qci !== undefined, [qci]);

  const initialization = useCallback(
    (
      context: DeclarationContext,
      obj: string,
      flattenFlields: FieldDesc[],
      updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
      updateFieldVisibility: (hidableFieldFullPathId: string, newVisibility: boolean) => void,
      updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
      updateFieldReadOnly: (readOnlyFieldFullPathId: string, isReadOnly: boolean) => void,
      updateFieldMandatory: (
        mandatoryFieldFullPathId: string,
        isMandatory: boolean,
        updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
      ) => void,
    ): void => {
      qcsFunc.current = {
        updateDeclaration,
        updateFieldVisibility,
        updateFieldErrors,
        updateFieldReadOnly,
        updateFieldMandatory,
      };
      initialize(context, obj, flattenFlields, editableDeclaration);
    },
    [initialize, editableDeclaration],
  );

  const showWarningsOrInformation = useCallback(
    (notificationFcType: 'warnings' | 'informations') => {
      if (fcState === null) return;

      const { warnings, informations } = fcState;

      if (notificationFcType === 'warnings') {
        for (const warning of warnings) {
          toast.warn(warning);
        }
      } else {
        for (const information of informations) {
          toast.info(information);
        }
      }
    },
    [fcState],
  );

  const showWarning = useCallback(() => {
    showWarningsOrInformation('warnings');
  }, [showWarningsOrInformation]);

  const showInformation = useCallback(() => {
    showWarningsOrInformation('informations');
  }, [showWarningsOrInformation]);

  const updateFormContext = useCallback(
    (fc: QCSFormContext) => {
      if (!qcsFunc.current) {
        fcDispatch({ type: FormContextAction.ON_ERROR, payload: fc });
        return;
      }

      fcDispatch({ type: FormContextAction.ON_SUCCESS, payload: fc });

      const { updateDeclaration, updateFieldVisibility, updateFieldReadOnly, updateFieldMandatory, updateFieldErrors } =
        qcsFunc.current;
      const fieldValues = updateFieldValues(fc);
      fieldValues.forEach((newValue: unknown, key: string) => {
        const newKey = getFullPathId(key);
        updateDeclaration(newKey, newValue as AllFieldValueTypes);
      });

      // Mise à jour de la visibilité
      const fieldVisibilities: CaseInsensitiveMap<string, boolean> = fc.fieldVisibilityFlag;

      fieldVisibilities.forEach((newVisibility: boolean, key: string) => {
        const newKey = getFullPathId(key);
        updateFieldVisibility(newKey, newVisibility);

        // Ajout de l'enregistrement de l'état pour envoyer dans la déclaration (internalData)
        registerNewState(newKey, InternalFieldState.Visible, newVisibility);
      });

      // Mise à jour du ReadOnly
      const readOnlyFields: CaseInsensitiveMap<string, boolean> = fc.fieldReadonlyFlag;

      readOnlyFields.forEach((newReadOnly: boolean, key: string) => {
        const newKey = getFullPathId(key);
        updateFieldReadOnly(newKey, newReadOnly);

        // Ajout de l'enregistrement de l'état pour envoyer dans la déclaration (internalData)
        registerNewState(newKey, InternalFieldState.ReadOnly, newReadOnly);
      });

      // Mise à jour du Mandatory
      const mandatoryFields: CaseInsensitiveMap<string, boolean> = fc.fieldMandatoryFlag;

      mandatoryFields.forEach((newMandatory: boolean, key: string) => {
        const newKey = getFullPathId(key);
        updateFieldMandatory(newKey, newMandatory, updateFieldErrors);

        // Ajout de l'enregistrement de l'état pour envoyer dans la déclaration (internalData)
        registerNewState(newKey, InternalFieldState.Mandatory, newMandatory);
      });
    },
    [fcDispatch, qcsFunc, updateFieldValues, getFullPathId, registerNewState],
  );

  const callController = useCallback(
    (controllerMethod: string, imageFieldId?: string): unknown => {
      const [fc, result] = callInterpreter(controllerMethod, imageFieldId);

      if (result !== undefined && fc) {
        updateFormContext(fc);
      }

      return result;
    },
    [callInterpreter, updateFormContext],
  );

  const onFormLoaded = useCallback(
    (flattenFlields: FieldDesc[]): void => {
      callController(QCFormEvent.ON_FORM_LOADED);
    },
    [callController],
  );

  const onFormPause = useCallback((): void => {
    callController(QCFormEvent.ON_FORM_PAUSE);
  }, [callController]);

  const onFormValidating = useCallback((): boolean => {
    const result = callController(QCFormEvent.ON_FORM_VALIDATING);

    if (result !== null && result instanceof QCSBool) {
      return result.getValue();
    }

    return true;
  }, [callController]);

  const onRowAdded = useCallback(
    (flattenFields: FieldDesc[]): void => {
      callController(QCFormEvent.ON_ROW_ADDED);
    },
    [callController],
  );

  const onRowDeleted = useCallback(
    (flattenFields: FieldDesc[], fullPathId: string): void => {
      callController(QCFormEvent.ON_ROW_DELETED);
    },
    [callController],
  );

  const onImageClick = useCallback(
    (imagesGroupFieldFullId: string, imagesGroupFieldId: string, fieldIdClicked: string): boolean => {
      setQCSBaseFieldId(imagesGroupFieldFullId);
      setQCSFieldId(imagesGroupFieldId);
      const result = callController(QCFormEvent.ON_IMAGE_CLICK, fieldIdClicked);

      if (result !== null && result instanceof QCSBool) {
        return result.getValue();
      }

      return true;
    },
    [callController, setQCSBaseFieldId, setQCSFieldId],
  );

  const displayInvalidErrorMessage = useCallback(() => {
    if (fcState === null) return;

    let errorMessage = t('formlib_message_invalid').toString();
    const { warnings } = fcState;

    if (warnings.length > 0) errorMessage += ` ${warnings.at(0)}`;

    toast.error(errorMessage);
  }, [t, fcState]);

  const setQCSFunc = useCallback((qcsFunctions: QCSFunctions) => {
    qcsFunc.current = qcsFunctions;
  }, []);

  return {
    onImageClick,
    initialization,
    onFormLoaded,
    callController,
    onFormPause,
    onFormValidating,
    onRowAdded,
    onRowDeleted,
    showWarning,
    showInformation,
    displayInvalidErrorMessage,
    isQCScriptForm,
    setQCSFunc,
  };
};

export default useFormEventQCScript;
