import React from 'react';
import { AppBar, Backdrop, Box, CircularProgress, Toolbar, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { TopBarProps } from './types';
import { MainBox, TopBarStyle } from './style';
import useData from './hooks';
import LeftItems from './LeftItems';
import RightItems from './RightItems';
import CenterItems from './CenterItems';
import { TopBarName } from './const';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const TopBar = ({ showLeftMenuButton, leftChildren, rightChildren, pageTitle }: TopBarProps) => {
  const { t } = useTranslation('common');
  const { isLoggingOut, showLeftMenu } = useData(pageTitle);
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <>
      <AppBar
        elevation={0}
        data-cy={TopBarName}
        color={isMobile ? 'default' : 'transparent'}
        position="sticky"
        id="topBarComponent"
        sx={TopBarStyle(theme, showLeftMenu)}
      >
        <Toolbar disableGutters>
          <Box sx={MainBox}>
            <LeftItems showLeftMenuButton={showLeftMenuButton}>{leftChildren}</LeftItems>
            <CenterItems pageTitle={pageTitle} showLeftMenu={false} searchView={false} />
            <RightItems>{rightChildren}</RightItems>
            <Backdrop open={isLoggingOut}>
              <CircularProgress />
            </Backdrop>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default observer(TopBar);
