export class CaseInsensitiveMap<T, U> extends Map<T, U> {
  set(key: T, value: U): this {
    return super.set(key, value);
  }

  get(key: T): U | undefined {
    if (typeof key === 'string') {
      const keyStr = key.toLowerCase();
      const result: T | undefined = this._search(keyStr);

      return result ? super.get(result) : undefined;
    }

    return super.get(key);
  }

  has(key: T): boolean {
    if (typeof key === 'string') {
      const keyStr = key.toLowerCase();
      const result: T | undefined = this._search(keyStr);

      return !!result;
    }

    return super.has(key);
  }

  private _search(key: string): T | undefined {
    const result = Array.from(super.keys()).find((keyArr: T) => {
      return typeof keyArr === 'string' ? (keyArr as string).toLowerCase() === key.toLowerCase() : false;
    });

    return result ? result : undefined;
  }
}
