import React from 'react';
import { FormControl, Box, Typography, InputAdornment, IconButton, useTheme } from '@mui/material';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { ForgottenPasswordMethodEnum, PasswordRulesType } from '../types';
import { CustomInputLabelStyle, CustomTextFieldStyle, TypoStyle } from '../styles';
import useData from './hooks';
import { ForgottenPasswordResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { dippedGreen500, pureWhiteColor, slateGrey700, slateGrey900, terraCotta500 } from '00.app/theme';
interface ForgetPasswordProps {
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  newPassword: string;
  resetCode?: string;
  setResetCode?: React.Dispatch<React.SetStateAction<string>>;
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordRules: React.Dispatch<React.SetStateAction<PasswordRulesType>>;
  passwordRules: PasswordRulesType;
  resetPasswordData: ForgottenPasswordResponse | undefined;
}

const ForgetPassword = ({
  newPassword,
  setNewPassword,
  confirmPassword,
  resetCode,
  setConfirmPassword,
  setResetCode,
  passwordRules,
  setPasswordRules,
  resetPasswordData,
}: ForgetPasswordProps) => {
  const { onCheckPasswordRules, t, showPassword, setShowPassword } = useData(setNewPassword, setPasswordRules);
  const theme = useTheme();
  return (
    <Box>
      <>
        {resetPasswordData && (
          <>
            <Typography variant="body2" sx={TypoStyle}>
              {resetPasswordData?.message}
            </Typography>
            <CustomInputLabelStyle>{`${t('forget_password.reset_code')}`}</CustomInputLabelStyle>
            <CustomTextFieldStyle
              data-cy="reset-code-input"
              margin="none"
              required
              fullWidth
              id="code"
              name="code"
              type={'tel'}
              value={resetCode}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                if (/^-?(\d*)?$/.test(event.target.value) && setResetCode) setResetCode(event.target.value);
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
            />
          </>
        )}
        <Box>
          <CustomInputLabelStyle
            htmlFor="outlined-adornment-new-password"
            sx={{
              color: theme.palette.mode === 'dark' ? `${pureWhiteColor}!important` : `${slateGrey700}!important`,
            }}
          >{`${t('forget_password.new_password')}`}</CustomInputLabelStyle>
          <FormControl variant="outlined" fullWidth>
            <CustomTextFieldStyle
              data-cy="password-input"
              id="outlined-adornment-new-password"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(event: React.ChangeEvent<{ value: string }>) => onCheckPasswordRules(event.target.value)}
              required
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CheckIcon fontSize="small" />
          <Typography sx={{ color: `${passwordRules.passLength ? dippedGreen500 : terraCotta500}` }} variant="body2">
            {`${t('forget_password.length_rule')}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CheckIcon fontSize="small" />
          <Typography sx={{ color: `${passwordRules.lowerCase ? dippedGreen500 : terraCotta500}` }} variant="body2">
            {`${t('forget_password.lowercase_rule')}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CheckIcon fontSize="small" />
          <Typography sx={{ color: `${passwordRules.upperCase ? dippedGreen500 : terraCotta500}` }} variant="body2">
            {`${t('forget_password.uppercase_rule')}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CheckIcon fontSize="small" />
          <Typography sx={{ color: `${passwordRules.digit ? dippedGreen500 : terraCotta500}` }} variant="body2">
            {`${t('forget_password.digit_rule')}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CheckIcon fontSize="small" />
          <Typography
            sx={{ color: `${passwordRules.specialCharacters ? dippedGreen500 : terraCotta500}` }}
            variant="body2"
          >
            {`${t('forget_password.special_char_rule')}`}
          </Typography>
        </Box>
        <Box>
          <CustomInputLabelStyle
            htmlFor="outlined-adornment-password"
            sx={{
              color: theme.palette.mode === 'dark' ? `${pureWhiteColor}!important` : `${slateGrey700}!important`,
            }}
          >{`${t('forget_password.confirm_password')}`}</CustomInputLabelStyle>
          <FormControl variant="outlined" fullWidth>
            <CustomTextFieldStyle
              data-cy="confirm-password-input"
              id="outlined-adornment-password"
              type={'password'}
              value={confirmPassword}
              onChange={(event: React.ChangeEvent<{ value: string }>) => setConfirmPassword(event.target.value)}
              required
            />
          </FormControl>
        </Box>
      </>
    </Box>
  );
};

export default observer(ForgetPassword);
