import { useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';

import evaluateComputedRule from '20.formLib/helpers/evals/evaluateComputedRule';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { Dependencies, AllFieldValueTypes, FieldDesc, ComputeDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import evalDependencies from '20.formLib/helpers/evalDependencies/evalDependencies';
import { OperatorResult } from '90.quickConnect.Models/enums';
import { ComputedFieldInfo } from '30.quickConnect.Stores/RootStore/ComputeStore/types';
import { getDurationTranslator } from '20.formLib/helpers/durationTranslator';
import { getDuration } from '90.quickConnect.Models/mappings';
import { isEqualObjects } from '80.quickConnect.Core/helpers/isEqualObjects';

const useData = (
  computeField: ComputeDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
  otherFields: FieldDesc[],
): void => {
  const { t } = useTranslation('declaration');

  const previousEvalResult = useRef<[AllFieldValueTypes, OperatorResult | undefined]>([undefined, undefined]);

  const {
    LoginStore: {
      signInInfos: { userParameterValue, userUPN, email },
    },
    ComputeStore: { setComputedFieldInfos },
  } = useStore();

  const { id, fullPathId, formula } = computeField as ComputeDesc;

  const computeFieldDependencies = useMemo(() => {
    if (formula) {
      const newDependencies = evalDependencies(formula, [], otherFields, fullPathId);
      const newVisibilityDependency: Dependencies = {
        id: id,
        fullPathId: fullPathId,
        rule: formula,
        dependencies: newDependencies,
      };
      return newVisibilityDependency;
    }
  }, [otherFields, formula, fullPathId, id]);

  const storageInComputeStoreAndUpdateDeclaration = useCallback(
    (evalResult: AllFieldValueTypes, evalResultType: OperatorResult | undefined) => {
      if (!evalResultType) return;

      let valueForComputedFieldInfos: AllFieldValueTypes = evalResult;

      if (evalResultType === OperatorResult.DURATION) {
        const durationText = getDurationTranslator(evalResult as Duration, t);
        updateDeclaration(fullPathId, durationText);
        // Dans le cas où on retourne un interval de durée

        valueForComputedFieldInfos = getDuration(evalResult as Duration);
      } else {
        updateDeclaration(fullPathId, typeof evalResult === 'number' ? roundTo(evalResult, 4) : evalResult);
      }

      const computedFieldInfo: ComputedFieldInfo = {
        value: valueForComputedFieldInfos,
        operatorResult: evalResultType,
      };
      setComputedFieldInfos(fullPathId, computedFieldInfo);
    },
    [fullPathId, setComputedFieldInfos, updateDeclaration, t],
  );

  const isPrimitive = useCallback(
    (value: AllFieldValueTypes): value is string | number | boolean | undefined =>
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      typeof value === 'undefined',
    [],
  );

  const isTheSameResult = useCallback(
    (nextResult: AllFieldValueTypes, previousResult: AllFieldValueTypes): boolean => {
      if (typeof nextResult !== typeof previousResult) return false;

      // La valeur est une primitive
      if (isPrimitive(nextResult)) return nextResult === previousResult;

      // La valeur est un objet
      return isEqualObjects(nextResult, previousResult);
    },
    [isPrimitive],
  );

  const [evalResult, evalResultType] = useMemo(() => {
    if (!computeFieldDependencies) return [undefined, undefined];

    const { rule, dependencies } = computeFieldDependencies;
    return evaluateComputedRule(t, rule, dependencies, toJS(userParameterValue), fullPathId, userUPN, email);
  }, [t, userParameterValue, fullPathId, userUPN, email, computeFieldDependencies]);

  useEffect(() => {
    const [prevEvalResult, prevEvalResultType] = previousEvalResult.current;
    if (computeFieldDependencies) {
      if (!isTheSameResult(evalResult, prevEvalResult) || evalResultType !== prevEvalResultType) {
        if (evalResultType === OperatorResult.ERROR) {
          if (evalResult !== undefined && evalResult !== null) {
            updateFieldErrors(fullPathId, [evalResult.toString()]);
            updateDeclaration(fullPathId, undefined);
          }
        } else {
          updateFieldErrors(fullPathId, []);
          // On met aussi à jour un observable afin de pouvoir stoker certaines infos
          storageInComputeStoreAndUpdateDeclaration(evalResult, evalResultType);
        }

        previousEvalResult.current = [evalResult, evalResultType];
      }
    }
  }, [
    evalResult,
    evalResultType,
    computeFieldDependencies,
    fullPathId,
    updateDeclaration,
    userParameterValue,
    t,
    updateFieldErrors,
    userUPN,
    email,
    storageInComputeStoreAndUpdateDeclaration,
    isTheSameResult,
  ]);
};

export default useData;
