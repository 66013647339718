import getRefDependencies from './getRefDependencies';
import getDualOperatorDependencies from './getDualOperatorDependencies';
import getPatternDependencies from './getPatternDependencies';
import { addIfNotPresent } from '80.quickConnect.Core/helpers';
import { OperatorType, QcOperator } from '90.quickConnect.Models/enums';
import { mapQcOperatorType } from '90.quickConnect.Models/mappings';
import {
  BaseQCOperator,
  Dependency,
  MultipleOperator,
  IIfOperator,
  FieldDesc,
  CtrlRefOperator,
} from '90.quickConnect.Models/models';

const evalDependencies = (
  baseQCOperator: BaseQCOperator,
  array: Dependency[],
  flattenFields: FieldDesc[],
  scopeFullPathId: string,
): Dependency[] => {
  const operatorType = mapQcOperatorType(baseQCOperator.type);
  switch (operatorType) {
    case OperatorType.Bitwise:
      switch (baseQCOperator.type) {
        case QcOperator.Parenthesis:
          getDualOperatorDependencies(baseQCOperator, array, flattenFields, scopeFullPathId);
          break;
        case QcOperator.Multi:
          const multipleOperator = baseQCOperator as MultipleOperator;
          multipleOperator.args.forEach((op) => evalDependencies(op, array, flattenFields, scopeFullPathId));
          break;
        default:
          break;
      }
      break;
    case OperatorType.Pattern:
      getPatternDependencies(baseQCOperator as CtrlRefOperator, array, flattenFields, scopeFullPathId);
      break;
    case OperatorType.Selector:
      const directRef = getRefDependencies(baseQCOperator, flattenFields, scopeFullPathId);
      if (directRef) addIfNotPresent<Dependency>(array, directRef);
      break;
    case OperatorType.Arithmetic:
    case OperatorType.Comparison:
      getDualOperatorDependencies(baseQCOperator, array, flattenFields, scopeFullPathId);
      break;
    case OperatorType.Conditional:
      const iIfOperator = baseQCOperator as IIfOperator;
      evalDependencies(iIfOperator.condition, array, flattenFields, scopeFullPathId);
      evalDependencies(iIfOperator.argIfFalse, array, flattenFields, scopeFullPathId);
      evalDependencies(iIfOperator.argIfTrue, array, flattenFields, scopeFullPathId);
      break;
    case OperatorType.Value:
    default:
      break;
  }
  return array;
};

export default evalDependencies;
