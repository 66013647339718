import React from 'react';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useData from './hooks';
import { MainBoxStyle } from './styles';
import { LoginAdalName } from './const';

const LoginAdal = () => {
  const { t } = useTranslation('login');
  const { isLogging } = useData(t);
  return (
    <Box data-cy={LoginAdalName} sx={MainBoxStyle}>
      <Backdrop open={isLogging}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
export default observer(LoginAdal);
