import { SxProps, Theme } from '@mui/material';
import { AdaptWidthToMenu } from '../WithMenu/styles';

export const TopBarHeight = 100;

export const ToolBar = 125;

export const BottomNavBarHeight = 90;

export const TopInboxesBarHeight = 250;

export const HeightWithoutTopBar = `calc(100vh - ${TopBarHeight}px)`;

export const HeightWithoutTopAndBottomBar = `calc(100vh - ${TopBarHeight}px - ${ToolBar} - ${BottomNavBarHeight}px)`;

export const HeightWithoutTopInboxesBar = `calc(100vh - ${TopInboxesBarHeight}px)`;

export const FullHeightWithoutTopBar = {
  xs: HeightWithoutTopAndBottomBar,
  sm: HeightWithoutTopAndBottomBar,
  md: HeightWithoutTopBar,
  lg: HeightWithoutTopBar,
};

export const FullHeightWithoutTopInboxesBar = {
  xs: HeightWithoutTopAndBottomBar,
  sm: HeightWithoutTopAndBottomBar,
  md: HeightWithoutTopInboxesBar,
  lg: HeightWithoutTopInboxesBar,
};

export const OverflowForDesktop = { xs: 'unset', sm: 'unset', md: 'auto', lg: 'auto' };

export const OverflowForMobile = { xs: 'auto', sm: 'auto', md: 'unset', lg: 'unset' };

export const MainBox: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  height: '100%',
  paddingLeft: '1vw',
  paddingRight: '1vw',
  width: '100%',
};

export const TopBarStyle = (theme: Theme, showLeftMenu: boolean): SxProps => ({
  display: 'flex',
  justifyContent: 'center',
  width: { xs: '100vw', sm: '100vw', md: 'calc(100vw - 1.2rem)', lg: 'calc(100vw - 1.2rem)' },
  borderTopLeftRadius: { md: '15px', lg: '15px' },
  margin: { md: '1.2em auto', lg: '1.2em auto' },
  height: `${TopBarHeight}px`,
  background:
    theme.palette.mode === 'light'
      ? 'url(/assets/qc-bg-topBar.jpg)'
      : 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/assets/qc-bg-topBar.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  // ...AdaptWidthToMenu(theme, showLeftMenu),
});

export const LeftItemsStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  flex: '1 1 0px',
};
