import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, IconButton, useTheme, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import useData from './hooks';
import { RepeatableQcFieldChildProps } from './types';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { FieldDesc } from '90.quickConnect.Models/models';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from '50.quickConnect.Fields/FieldsTypes/Containers/GroupQcField/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';
import { FieldsName } from '50.quickConnect.Fields/Fields/const';

const RepeatableQcFieldChild = ({
  field,
  updateDeclaration,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  removeItem,
  index,
  forceExpand,
  parentId,
  context,
  updateFieldErrors,
  updateItemsForThisField,
  openByImagesGroupField,
}: RepeatableQcFieldChildProps) => {
  const groupField = field as FieldDesc;
  const { fullPathId, items } = groupField;
  const { t, nameWithId, isExpanded, handleChange } = useData(
    parentId,
    forceExpand,
    groupField,
    updateDeclaration,
    setSelectedIndex,
    openByImagesGroupField,
  );

  const customTheme = useTheme();
  const { breakpoints } = customTheme;
  return (
    <Box data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <CustomAccordion
        theme={customTheme}
        expanded={isExpanded}
        onChange={() => handleChange(isExpanded)}
        elevation={0}
      >
        <CustomAccordionSummary
          theme={customTheme}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1a-content1-${nameWithId}`}
          id={`panel1a-content1-${nameWithId}`}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                witdh: '100%',
              }}
              id="coucou"
            >
              <Tooltip title={t('formlib_array_deleted_title').toString()}>
                <span>
                  <IconButton
                    data-cy={`${nameWithId}-remove`}
                    color="primary"
                    onClick={(event) => removeItem(event, fullPathId)}
                    disabled={isReadOnly}
                  >
                    <DeleteOutlineRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Typography mr={1}>{index + 1}</Typography>
            </Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails theme={customTheme}>
          {items.map((item) => {
            const key = getFieldKey(item);
            return item?.importance ? (
              <Box sx={C2SImportant(item.importance)}>
                <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                  <FieldRenderer
                    key={key}
                    field={item}
                    updateDeclaration={updateDeclaration}
                    selectedIndex={selectedIndex}
                    updateItemsForThisField={updateItemsForThisField}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly || item.fieldIsReadOnly}
                    flattenFields={flattenFields}
                    setDeclaration={setDeclaration}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                  />
                </MandatoryField>
              </Box>
            ) : (
              <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                <FieldRenderer
                  key={key}
                  field={item}
                  updateDeclaration={updateDeclaration}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  updateItemsForThisField={updateItemsForThisField}
                  isReadOnly={isReadOnly || item.fieldIsReadOnly}
                  flattenFields={flattenFields}
                  setDeclaration={setDeclaration}
                  context={context}
                  updateFieldErrors={updateFieldErrors}
                />
              </MandatoryField>
            );
          })}
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  );
};
export default observer(RepeatableQcFieldChild);
