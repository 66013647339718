import { Breakpoints, Palette, SxProps, Theme } from '@mui/material';
import { blackColor, dippedGreen500, slateGrey300, slateGrey400, slateGrey500, textBlackColor } from '00.app/theme';

export const FieldBottomMargin = (breakpoints: Breakpoints): SxProps => ({
  [breakpoints.up('md')]: {
    marginBottom: '20px',
  },
  [breakpoints.down('md')]: {
    marginBottom: '10px',
  },
});

export const FlexRow: SxProps = { display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' };

export const FlexColumn: SxProps = { display: 'flex', flexDirection: 'column', width: '100%' };

export const FlexMainBoxRow = (breakpoints: Breakpoints): SxProps => ({
  ...FlexRow,
  ...FieldBottomMargin(breakpoints),
});

export const FieldMainBox = (breakpoints: Breakpoints): SxProps => ({
  ...FlexColumn,
  ...FieldBottomMargin(breakpoints),
});

export const OpenRightPanelBox: SxProps = { width: '100%', '&:hover': { opacity: '0.7' }, pointer: 'pointer' };
export const BoxForLabel = (palette: Palette): SxProps => ({
  border: `1px solid  ${palette.mode === 'dark' ? 'rgb(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'}`,
  borderRadius: '4px',
  padding: '5px',
  cursor: 'pointer',
  '&:hover': { opacity: '0.7' },
});
