import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseHydrateDeclarationWithInternalDataProps } from '10.quickConnect.app/components/domain/Declaration/hooks/useHydrateDeclaration/types';
import { EditDesc, FieldData, FieldDesc } from '90.quickConnect.Models/models';
import { FieldType, Mandatory, StateInternalData } from '90.quickConnect.Models/enums';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import DataSourceValue from '30.quickConnect.Stores/helpers/DataSourceValue';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { useStore } from '30.quickConnect.Stores';
import { useFieldAutoComplete } from '50.quickConnect.Fields/hooks/useFieldAutoComplete';

const useHydrateDeclarationWithInternalData = (): UseHydrateDeclarationWithInternalDataProps => {
  // Tag
  const tag =
    '10.quickConnect.app/components/domain/Declaration/hooks/useHydrateDeclaration/useHydrateDeclarationWithInternalData.ts';
  // Lib Hooks
  const { t } = useTranslation('declaration');
  // Custom Hooks
  const {
    EquipmentsStore: { getSearchFieldValuesBySchemaAndEntityInstance, getSearchFieldValuesAzureAD, setIsInDataSource },
    DeclarationStore: { parseInternalData },
  } = useStore();

  const { registerValidState, unRegisterValidState } = useFieldAutoComplete();

  const getFullIdAndState = useCallback((id: string): [string, StateInternalData] => {
    const index = id.lastIndexOf('.');

    let stateField = StateInternalData.Undef;

    switch (id.substring(index + 1)) {
      case '(state)':
        stateField = StateInternalData.State;
        break;

      case '(ds)':
        stateField = StateInternalData.DataSource;
        break;

      case '(acs)':
        stateField = StateInternalData.AutoCompleteSchema;
        break;

      case '(acc)':
        stateField = StateInternalData.AutoCompleteContact;
        break;

      default:
        stateField = StateInternalData.Undef;
        break;
    }

    return [id.substring(0, index), stateField];
  }, []);

  const getStateValueBoolean = useCallback((val: string): boolean | null => {
    switch (val) {
      case '1':
        return true;

      case '0':
        return false;

      default:
        return null;
    }
  }, []);

  const hydrateAutoComplete = useCallback(
    async (fieldToHydrate: EditDesc, valueInternalData: string, isEditable: boolean) => {
      try {
        if (!isEditable) return;

        const { dataSource, fullPathId, value } = fieldToHydrate;

        if (!dataSource)
          throw new Error(
            `L'attribut "dataSource" ne peut être undefined pour le champ texte avec le fullPathId ${fullPathId}`,
          );

        if (typeof value !== 'string') {
          throw new Error(`Le type de la valeur doit être string pour le champ texte avec le fullPathId ${fullPathId}`);
        }

        // Appel API
        const isChanged = await getSearchFieldValuesBySchemaAndEntityInstance(dataSource, valueInternalData, value, t);

        // Mise a jour de la valeur
        if (isChanged) fieldToHydrate.value = '';
      } catch (error: unknown) {
        errorHandler(tag, error, 'hydrateAutoComplete');

        fieldToHydrate.value = '';
      }
    },
    [getSearchFieldValuesBySchemaAndEntityInstance, t, tag],
  );

  const hydrateAutoCompleteAAD = useCallback(
    async (fieldToHydrate: EditDesc, valueInternalData: string, isEditable: boolean) => {
      try {
        if (!isEditable) return;

        const { dataSource, fullPathId, value } = fieldToHydrate;

        if (!dataSource)
          throw new Error(
            `L'attribut "dataSource" ne peut être undefined pour le champ texte avec le fullPathId ${fullPathId}`,
          );

        // Appel API
        const response = await getSearchFieldValuesAzureAD(valueInternalData);

        if (!response) {
          fieldToHydrate.value = '';
          return;
        }

        const { searchUsersResults } = response;

        if (searchUsersResults.length !== 1) {
          fieldToHydrate.value = '';
          return;
        }

        const [itemToFound] = searchUsersResults;

        const { value: nextValue } = itemToFound;

        // Mise a jour de la valeur
        if (nextValue !== value) fieldToHydrate.value = '';
      } catch (error: unknown) {
        errorHandler(tag, error, 'hydrateAutoCompleteAAD');

        fieldToHydrate.value = '';
      }
    },
    [getSearchFieldValuesAzureAD, tag],
  );

  const hydrateState = useCallback(
    (fieldToHydrate: FieldDesc, value: string, isEditable: boolean): void => {
      const values: string[] = value.split('');

      if (values.length !== 4)
        throw new Error('useHydrateDeclarationWithInternalData file: hydrateState method: incorrect format for value');

      values.forEach((val: string, index: number) => {
        switch (index) {
          case 0: {
            // Visibility
            const boolValue = getStateValueBoolean(val);
            if (typeof boolValue === 'boolean') fieldToHydrate.isVisible = boolValue;
            break;
          }
          case 1: {
            // ReadOnly
            const boolValue = getStateValueBoolean(val);
            if (typeof boolValue === 'boolean') fieldToHydrate.fieldIsReadOnly = isEditable ? boolValue : true;
            break;
          }

          case 2: {
            // Mandatory
            const boolValue = getStateValueBoolean(val);
            if (typeof boolValue === 'boolean') fieldToHydrate.mandatory = boolValue ? Mandatory.Required : undefined;
            break;
          }

          case 3: {
            // Valid
            const boolValue = getStateValueBoolean(val);
            if (fieldToHydrate.fieldType === FieldType.Text && typeof boolValue === 'boolean') {
              if (boolValue) {
                setIsInDataSource(fieldToHydrate.fullPathId, true);
                registerValidState(fieldToHydrate.fullPathId);
              } else {
                setIsInDataSource(fieldToHydrate.fullPathId, false);
                unRegisterValidState(fieldToHydrate.fullPathId);
              }
            }
            break;
          }

          default:
            break;
        }
      });
    },
    [getStateValueBoolean, registerValidState, unRegisterValidState, setIsInDataSource],
  );

  const hydrateFieldState = useCallback(
    async (
      fieldData: FieldData,
      flattenFields: FieldDesc[],
      isEditable: boolean,
      isDuplicated: boolean,
    ): Promise<void> => {
      // 1. Rechercher le champ à hydrater
      const { id, value, instanceId, schemaId, title, code } = fieldData;
      const [fullId, stateField] = getFullIdAndState(id);
      const fieldToHydrate: FieldDesc | undefined = flattenFields.find((f: FieldDesc) => f.fullPathId === fullId);

      if (!fieldToHydrate) throw new Error('hydrateFieldState method: Aucun champ trouvé avec le fullId:' + fullId);

      if (isDuplicated && fieldToHydrate.canDuplicate === false) return;

      switch (stateField) {
        case StateInternalData.State:
          hydrateState(fieldToHydrate, value as string, isEditable);
          break;

        case StateInternalData.DataSource:
          const dsValue: DataSourceValue = new DataSourceValue(schemaId, instanceId, code, title, value as FieldData[]);
          await dsValue.buildEntityDataFromDataSourceValue();
          break;

        case StateInternalData.AutoCompleteSchema:
          await hydrateAutoComplete(fieldToHydrate as EditDesc, value as string, isEditable);
          break;

        case StateInternalData.AutoCompleteContact:
          await hydrateAutoCompleteAAD(fieldToHydrate as EditDesc, value as string, isEditable);
          break;

        default:
          throw new Error(
            'useHydrateDeclarationWithInternalData file: hydrateFieldState method: Invalid StateInternalData',
          );
      }
    },
    [getFullIdAndState, hydrateState, hydrateAutoComplete, hydrateAutoCompleteAAD],
  );

  const initInternalFieldData = useCallback(
    async (
      flattenFields: FieldDesc[],
      internalData: string | null | undefined,
      isEditable: boolean,
      isDuplicated: boolean,
    ): Promise<void> => {
      try {
        // 1. Normaliser l'internalData s'il existe
        if (!internalData) return;

        if (typeof internalData === 'string' && !internalData.startsWith('['))
          throw new Error(`Donnée incorrecte pour parser: ${internalData.toString()}`);

        const fieldsData: FieldData[] =
          internalData === null ? [] : typeof internalData === 'string' ? JSON.parse(internalData) : internalData;

        const allFields = flatten(flattenFields, (i) => i.items);

        // 2. Hydrater les champs du formulaire
        await Promise.all(
          fieldsData.map(async (fieldData: FieldData) => {
            await hydrateFieldState(fieldData, allFields, isEditable, isDuplicated);
          }),
        );

        if (typeof internalData === 'string') parseInternalData(internalData);
      } catch (error) {
        errorHandler(tag, error, 'initInternalFieldData');
      }
    },
    [hydrateFieldState, tag, parseInternalData],
  );

  return {
    initInternalFieldData,
  };
};

export default useHydrateDeclarationWithInternalData;
