import { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { CustomAxiosResponse } from '40.quickConnect.DataAccess/axios/types';
import { FormLibVersion } from 'const';
import getSessionId from '80.quickConnect.Core/helpers/getSessionId';

/// On précise any car AxiosRequestConfig, AxiosResponse et AxiosError n'ont pas la prop '.config.data'
const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  config.headers.set('QC-CompanionId', process.env.REACT_APP_QC_COMPANION_ID ?? '');
  config.headers.set('QC-LibVersion', FormLibVersion);
  config.headers.set('QC-SessionId', getSessionId());

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: any): CustomAxiosResponse<any> => {
  const newRes = { ...response };
  return newRes;
};

const onResponseError = (error: any): Promise<AxiosError<any>> => {
  const newError = { ...error };
  return Promise.reject(error as AxiosError<any>);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export const setupTokenInterceptor = (axiosInstance: AxiosInstance, TOKEN: string): AxiosInstance => {
  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers.set('Authorization', `Bearer ${TOKEN}`);
    return config;
  });
  return axiosInstance;
};
