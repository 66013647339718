import { Box, Tooltip, IconButton, SvgIcon, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import useData from './hooks';
import { LogoBox } from './style';
import { LeftItemsProps } from './types';
import { leftItemsName } from './const';
import { showOnMobile, hideOnMobile } from '20.formLib/sharedStyles';
import { LogoStandardMediumSvg } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/Medium';
import { LeftItemsStyle } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { iconsLogoStyle } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/styles';
import { LogoStandardSmallSvg } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/Small';
import { useWindowSize } from '80.quickConnect.Core/hooks';

const LeftItems: React.FC<LeftItemsProps> = ({ children, showLeftMenuButton }: LeftItemsProps) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const { t } = useTranslation('common');
  const { goHome, showLeftMenu, setShowLeftMenu } = useData(showLeftMenuButton);

  const [logo, setLogo] = useState<string | null>(null);

  const [currentOuCode, setCurrentOuCode] = useState<string | null>(() => {
    const sessionStore = sessionStorage.getItem('LoginStore');
    return sessionStore ? JSON.parse(sessionStore).currentOuCode : null;
  });

  // Fonction pour remonter la hiérarchie des UO et trouver un logo
  const findLogoInHierarchy = (ouCode: string, units: any[]): string | null => {
    // Split the ouCode to get its levels
    const levels = ouCode.split('\\');
    while (levels.length > 0) {
      const currentCode = levels.join('\\');
      const unit = units.find((u: any) => u.code === currentCode);
      if (unit && unit.logo) {
        return unit.logo; // Retourner le logo s'il est trouvé
      }
      levels.pop(); // Remonter d'un niveau dans la hiérarchie
    }
    return null; // Aucun logo trouvé
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const sessionStore = sessionStorage.getItem('LoginStore');
      const newCurrentOuCode = sessionStore ? JSON.parse(sessionStore).currentOuCode : null;
      if (newCurrentOuCode !== currentOuCode) {
        setCurrentOuCode(newCurrentOuCode);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Nettoyage
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [currentOuCode]);

  useEffect(() => {
    // Récupérer userOrganizationnalUnits depuis localStorage
    const localStore = localStorage.getItem('LoginStore');
    const userOrganizationnalUnits = localStore ? JSON.parse(localStore).userOrganizationnalUnits : [];

    if (currentOuCode && userOrganizationnalUnits.length > 0) {
      const foundLogo = findLogoInHierarchy(currentOuCode, userOrganizationnalUnits);
      setLogo(foundLogo);
    }
  }, [currentOuCode]);

  return (
    <Box data-cy={leftItemsName} sx={LeftItemsStyle}>
      {showLeftMenuButton && !showLeftMenu && (
        <IconButton onClick={() => setShowLeftMenu(true)}>
          <MenuRoundedIcon />
        </IconButton>
      )}
      <>
        {/* {showBackButton ? <BackButton /> : <></>} */}
        <Box sx={showOnMobile}>
          {/* {showBackButton ? <BackButton /> : <MainMobileMenu />} */}
          <Box sx={{ ...LogoBox }}>
            <Tooltip title={t('qcapp_common_home_page').toString()}>
              <Box sx={iconsLogoStyle(theme)} onClick={goHome} data-cy={`${leftItemsName}-goHome-mobile`}>
                {logo ? (
                  <img src={logo} alt="Organization Logo" style={{ height: '32px', width: 'auto' }} />
                ) : (
                  <SvgIcon component={LogoStandardMediumSvg} viewBox="inherit" fontSize="small" />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ ...hideOnMobile }}>
          <Tooltip title={t('qcapp_common_home_page').toString()}>
            <Box onClick={goHome} data-cy={`${leftItemsName}-goHome-desktop`}>
              {logo ? (
                <img src={logo} alt="Organization Logo" style={{ height: '32px', width: 'auto' }} />
              ) : (
                <SvgIcon
                  component={
                    width && width > theme.breakpoints.values.lg ? LogoStandardMediumSvg : LogoStandardSmallSvg
                  }
                  viewBox="inherit"
                  fontSize="small"
                />
              )}
            </Box>
          </Tooltip>
        </Box>
      </>
      <Box sx={{ marginLeft: '1vw' }}>{children}</Box>
    </Box>
  );
};

export default observer(LeftItems);
