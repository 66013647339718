import { useCallback } from 'react';
import { validate } from 'uuid';
import { useStore } from '30.quickConnect.Stores';
import { isListField } from '90.quickConnect.Models/guards';
import { ComboDesc, FieldDesc, HierarchicalDesc, HierarchicalChoice, Choice } from '90.quickConnect.Models/models';
import { choiceListParse } from '80.quickConnect.Core/helpers/choiceListParsers';
import { FieldType, SharedListType } from '90.quickConnect.Models/enums';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { flatten } from '80.quickConnect.Core/helpers';

export const useHydrateDefaultChoices = () => {
  const {
    SharedListStore: { getSharedList },
  } = useStore();

  const hasValidateListId = useCallback(
    (listId: string | undefined): listId is string => !!listId && validate(listId),
    [],
  );

  const setDefaultChoicesFromListId = useCallback(
    async (listId: string, f: ComboDesc | HierarchicalDesc): Promise<void> => {
      if (!f.defaultValue) return;
      const sharedList = await getSharedList(listId);

      if (!sharedList) return;

      const listChoices =
        f.fieldType === FieldType.HierarchicalList
          ? flatten(sharedList.hierarchicalChoices, (h) => h.children)
          : sharedList.data;

      const newValues = choiceListParse(f.defaultValue, listChoices, sharedList.listType, CustomLogger.getInstance());

      f.value =
        f.fieldType === FieldType.Combo || f.fieldType === FieldType.RadioList
          ? newValues.length === 1
            ? newValues.at(0)
            : undefined
          : newValues;
    },
    [getSharedList],
  );

  const setDefaultChoicesFromListDef = useCallback(
    (listChoices: Choice[] | HierarchicalChoice[] | undefined, f: ComboDesc | HierarchicalDesc) => {
      if (!f.defaultValue || !listChoices) return;

      const sharedListType =
        f.fieldType === FieldType.HierarchicalList ? SharedListType.Hierarchical : SharedListType.Simple;

      const newValues = choiceListParse(f.defaultValue, listChoices, sharedListType, CustomLogger.getInstance());

      f.value =
        f.fieldType === FieldType.Combo || f.fieldType === FieldType.RadioList
          ? newValues.length === 1
            ? newValues.at(0)
            : undefined
          : newValues;
    },
    [],
  );

  const populateDefaultChoices = useCallback(
    async (fields: FieldDesc[]): Promise<void> => {
      const newFields = await Promise.all(
        fields.map(async (f: FieldDesc) => {
          if (isListField(f)) {
            if (hasValidateListId(f.listId)) await setDefaultChoicesFromListId(f.listId, f);
            else setDefaultChoicesFromListDef(f.listChoice, f);
          }

          if (f.items.length > 0) {
            populateDefaultChoices(f.items);
          }

          return f;
        }),
      );

      fields = newFields;
    },
    [hasValidateListId, setDefaultChoicesFromListDef, setDefaultChoicesFromListId],
  );

  return { populateDefaultChoices };
};
