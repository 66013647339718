import { useEffect, useMemo } from 'react';
import { createTheme } from '@mui/material';
import {
  blackColor,
  getComponents,
  getMuiLocal,
  ghostWhite,
  lightPalette,
  pureWhiteColor,
  textBlackColor,
  darkPalette,
  slateGrey900,
} from './theme';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { useTabId } from '80.quickConnect.Core/hooks';

const handleWindowFocus = () => {
  const loginStoreSession = sessionStorage.getItem('LoginStore');
  if (loginStoreSession) {
    const { currentOuCode } = JSON.parse(loginStoreSession);

    if (currentOuCode) {
      // On récupère le LoginStore de localStorage (clé avec la valeur contenant currentOUCode)
      const loginStoreLocal = localStorage.getItem('LoginStore');
      if (loginStoreLocal) {
        const loginStoreLocalData = JSON.parse(loginStoreLocal);
        // On met à jour lastSetOuCode (localStorage partagé) avec currentOuCode (SessionStorage)
        loginStoreLocalData.lastSetOuCode = currentOuCode;
        // On sauvegarde la mise à jour dans localStorage
        localStorage.setItem('LoginStore', JSON.stringify(loginStoreLocalData));
      }
    }
  }
};

export const useHooks = (): UseDataProps => {
  // On récupère le store
  const {
    UserSettingsStore: { muiMode },
  } = useStore();

  // Permet de générer un Id pour chaque tabs du navigateur
  useTabId();

  // Update the theme only if the mode changes
  const theme = useMemo(() => {
    const typoStd = {
      fontFamily: [
        'Rubik-var',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
    };

    const titleTypo = {
      ...typoStd,
      fontFamily: [...(typoStd.fontFamily as string).split(',').reverse(), 'N27-Regular'].reverse().join(','),
    };
    const partialTheme = createTheme();
    return createTheme(
      {
        typography: {
          h1: titleTypo,
          h2: titleTypo,
          h3: titleTypo,
          h4: titleTypo,
          h5: titleTypo,
          h6: titleTypo,
          subtitle1: typoStd,
          subtitle2: typoStd,
          body1: typoStd,
          body2: typoStd,
          button: typoStd,
          caption: typoStd,
          overline: typoStd,
        },
        palette: muiMode === 'light' ? lightPalette : darkPalette,
        components: getComponents(muiMode, partialTheme),
      },
      getMuiLocal(), // locale FR pour Mui
    );
  }, [muiMode]);

  // On ajoute des hooks
  useEffect(() => {
    document.title = 'Quick Connect';
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = muiMode === 'light' ? ghostWhite : slateGrey900;
    document.body.style.color = muiMode === 'light' ? textBlackColor : pureWhiteColor;
  }, [muiMode]);

  // On ajoute les listeners pour les événements focus
  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);

    // On nettoie les listeners à la désactivation du composant
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  return { theme };
};
