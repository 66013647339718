import { TFunction } from 'i18next';
import { intervalToDuration } from 'date-fns';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export const operationWithTimes = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  // Tests sur les paramètres fournis
  if (
    (typeof ref1Value === 'string' && isNaN(Date.parse(ref1Value))) ||
    (typeof ref2Value === 'string' && isNaN(Date.parse(ref2Value)))
  )
    return [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR];

  // Mutation sur le dateTime car avant on passer directement ref1Value & ref2Value. Créons un nouvel objet Date.

  const date1 = new Date(ref1Value as string);
  const date2 = new Date(ref2Value as string);
  date1.setDate(new Date(0).getTime());
  date2.setDate(new Date(0).getTime());
  let result;

  if (type === QcOperator.Subst) {
    date1.setSeconds(0, 0);
    date2.setSeconds(0, 0);
    result = date1.setSeconds(0, 0) - date2.setSeconds(0, 0);
  } else {
    return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
  }

  if (result >= 0 || result <= 0) {
    const { hours, minutes } = intervalToDuration({ start: date1, end: date2 });
    // if (hours === 0 && minutes === 0) return ['', OperatorResult.STRING];

    // const hoursStr: string =
    //   hours && Math.abs(hours) > 1 ? t('qcapp_declaration_duration_to_hours', { count: hours }).toString() : '';

    // const minutesStr: string =
    //   minutes && Math.abs(minutes) > 1 ? t('qcapp_declaration_duration_to_minutes', { count: minutes }).toString() : '';

    // const timeStr: string = hoursStr + ' ' + minutesStr;

    // return [timeStr, OperatorResult.STRING];

    const returnDuration: Duration = {
      hours,
      minutes,
    };

    if (result < 0) {
      if (returnDuration.hours) returnDuration.hours = -returnDuration.hours;
      else if (returnDuration.minutes) returnDuration.minutes = -returnDuration.minutes;
    }

    return [returnDuration, OperatorResult.DURATION];
  } else {
    return [t('computed_fields_errors_eval_error').toString(), OperatorResult.STRING];
  }
};
