import { Buffer } from 'buffer';
import bigDecimal from 'js-big-decimal';
import { action, makeAutoObservable, observable, computed, toJS } from 'mobx';
import JSONBig from 'json-bigint';
import RootStore from '30.quickConnect.Stores/RootStore';
import { AbortRequestsStore } from '30.quickConnect.Stores/RootStore/interfaces';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { FieldValue, QCScriptObjDTO } from '20.formLib/DeclarationContainer/AllResolvers/QCScriptsResolver/types';
import { FormContextDico } from '20.formLib/helpers/QCScriptLib/bridge/types/qcsFormContext';
import { CaseInsensitiveMap } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/CaseInsensitiveMap';
import {
  AllFieldValueTypes,
  CheckBoxListDesc,
  Choice,
  ComboDesc,
  DeclarationContext,
  FieldDesc,
  HierarchicalChoice,
  HierarchicalDesc,
} from '90.quickConnect.Models/models';
import { QCScriptObj } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCScriptObj';
import { QCScriptFunctionInfo } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCScriptFunctionInfo';
import { QCSHost } from '20.formLib/helpers/QCScriptLib/bridge/QCSHost';
import { IQCSHost } from '20.formLib/helpers/QCScriptLib/interfaces/IQCSHost';
import { QCSInterpreter } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSInterpreter';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { QCSFormContext } from '20.formLib/helpers/QCScriptLib/bridge/QCSFormContext';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { FieldType, QCFormEvent } from '90.quickConnect.Models/enums';
import { IQCSFormContext } from '20.formLib/helpers/QCScriptLib/bridge/IQCSFormContext';
import {
  errorHandler,
  findChoiceInChoiceList,
  findChoiceInHierarchicalChoice,
  hasValueForThisProperty,
} from '80.quickConnect.Core/helpers';
import {
  isAddressData,
  isAlertValueStringFormat,
  isAnAlertChoice,
  isChoice,
  isChoiceList,
  isCoordinates,
} from '90.quickConnect.Models/guards';
import { mapChoiceListFromHierarchicalChoiceList } from '90.quickConnect.Models/mappings';
import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';

class QCScriptStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/QCScriptStore/index.ts';

  shouldAbortRequests = false;

  RootStore: RootStore;

  logger: CustomLogger;

  flattenFields: FieldDesc[] = [];

  qcscriptObj: QCScriptObj | undefined;

  host: IQCSHost | undefined;

  qci: QCSInterpreter | undefined;

  context: DeclarationContext | undefined;

  // Flag permettant de savoir si le formulaire est éditable ou en lecture seule
  _formIsReadOnly = true;

  // QCScriptObj
  controllerMethod = '';
  qcsBaseFieldId = '';
  qcsFieldId = '';

  toastId: string | number | undefined;

  // Flag d'activation du mode Debug de l'interpréteur
  isDebugModeEnabled = false;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.RootStore = rootStore;
    this.logger = logger;
    makeAutoObservable(
      this,
      {
        shouldAbortRequests: observable,
        flattenFields: observable,
        fieldValue: computed,
        formContextDico: computed,
        controllerMethod: observable,
        qcsBaseFieldId: observable,
        qcsFieldId: observable,
        isDebugModeEnabled: observable,

        setShouldAbortRequests: action,
        setIsDebugModeEnabled: action,
        updateFlattenFieldValue: action,
      },
      { autoBind: true },
    );
  }

  initialize = (
    context: DeclarationContext,
    obj: string,
    flattenFields: FieldDesc[],
    formIsReadOnly: boolean,
  ): void => {
    this._formIsReadOnly = formIsReadOnly;
    this.setFlattenFields(flattenFields);
    this.context = context;
    const objParsed: QCScriptObjDTO = JSONBig.parse(obj);
    const functionsInitialized = objParsed.functions?.map(
      (fct: QCScriptFunctionInfo) => new QCScriptFunctionInfo(fct.name, fct.memoryAddress),
    );
    const bytes = Buffer.from(objParsed.pCode, 'base64');

    const pCodeBin: Uint8Array = new Uint8Array(bytes);

    this.qcscriptObj = new QCScriptObj({ ...objParsed, functions: functionsInitialized, pCode: pCodeBin });
    this._setHost();
    this._setQCInterpreter();

    if (this.qci && this.qcscriptObj) {
      this.qci.link(this.qcscriptObj);
    }
  };

  callingNewInterpreter = (sid: string) => {
    const field = this.flattenFields.find(({ fullPathId }: FieldDesc) => fullPathId === sid);

    if (!field) return;

    if (!field.controller || field.controller === '') return;

    const previousBaseScope = this.qcsBaseFieldId;
    const previousFieldId = this.qcsFieldId;
    this.setQCSBaseFieldId(sid);
    this.setQCSFieldId(field.fullPathId);
    this.callInterpreter(field.controller, undefined, true);
    this.setQCSFieldId(previousFieldId);
    this.setQCSBaseFieldId(previousBaseScope);
  };

  callInterpreter = (
    controllerMethod: string,
    onImageClickedFieldId?: string,
    keepPreviousHost = false,
  ): [QCSFormContext | null, unknown] => {
    let resultInterpreter: unknown = null;
    try {
      if (this.host && !keepPreviousHost) this.host.setHost(this);

      if (this.host && this.qci) {
        let baseFieldScope = '';
        let fieldId = '';
        switch (controllerMethod) {
          case QCFormEvent.ON_FORM_VALIDATING:
          case QCFormEvent.ON_FORM_PAUSE:
          case QCFormEvent.ON_FORM_LOADED:
            break;

          case QCFormEvent.ON_IMAGE_CLICK:
          default:
            baseFieldScope = this.qcsBaseFieldId ?? '';
            fieldId = this.qcsFieldId;
            break;
        }
        this.host.setBaseFieldScope(baseFieldScope);
        resultInterpreter = this.host.callQCScript(this.qci, controllerMethod, fieldId, onImageClickedFieldId);
      }
    } catch (error: unknown) {
      errorHandler(QCScriptStore.TAG, error, 'callInterpreter');
      this.logger.trace(QCScriptStore.TAG, `host.callQCScript failed for methodName: ${controllerMethod}`);
    }
    return [this.host?.getFormContext() ?? null, resultInterpreter];
  };

  updateFlattenFieldValue(fullId: string, nextValue: AllFieldValueTypes) {
    if (this.flattenFields.length === 0) return;

    const newFlattenFields = this.flattenFields.reduce(
      (acc: FieldDesc[], f: FieldDesc) =>
        fullId === f.fullPathId ? [...acc, { ...f, value: nextValue }] : [...acc, f],
      [],
    );

    this.setFlattenFields(toJS(newFlattenFields));
  }

  updateFieldValues = (fc: QCSFormContext): CaseInsensitiveMap<string, unknown> => {
    const fieldValues: CaseInsensitiveMap<string, unknown> = fc.fieldUpdated;
    fieldValues.forEach((newValue: unknown, key: string) => {
      let newValueForUpdate: AllFieldValueTypes = undefined;

      switch (true) {
        case typeof newValue === 'bigint':
        case typeof newValue === 'number':
          let bd = new bigDecimal(newValue as bigint | number);
          bd = bd.round(8);
          newValueForUpdate = Number(bd.getValue());
          break;

        case typeof newValue === 'string':
          //
          newValueForUpdate = (newValue as bigint | string | number).toString();
          break;

        case Array.isArray(newValue):
          if ((newValue as string[]).every((value) => typeof value === 'string')) {
            newValueForUpdate = (newValue as string[]).join(', ');
          } else {
            newValueForUpdate = newValue as (Choice | HierarchicalChoice)[];
          }
          break;

        // Case des dates
        case newValue instanceof DateTimeExtension:
          newValueForUpdate = newValue as AllFieldValueTypes;
          break;

        case newValue === null || (newValue instanceof QCSBaseObject && Object.keys(newValue).length === 0):
          newValueForUpdate = undefined;
          break;

        default:
          newValueForUpdate = newValue as AllFieldValueTypes;
          break;
      }

      fieldValues.set(key, newValueForUpdate);
    });

    fc.resetFieldUpdated();
    return fieldValues;
  };

  getFullPathId = (key: string): string => {
    const field = this.flattenFields.find((f: FieldDesc | undefined) => f?.fullPathId === key || f?.id === key);

    return field?.fullPathId ?? key;
  };

  resetStore = (): void => {
    this.flattenFields = [];
    this.qcscriptObj = undefined;
    this.host = undefined;
    this.qci = undefined;
    this.context = undefined;
    this.controllerMethod = '';
    this.qcsBaseFieldId = '';
    this.qcsFieldId = '';
  };

  get fieldValue(): FieldValue[] {
    return this.flattenFields.reduce((acc: FieldValue[], { fullPathId, value }: FieldDesc) => {
      // Permet d'éviter certains bug dans QCScript de type cannot read properties of undefined
      const valueOptimizeForQCScript = value ?? null;
      return [...acc, [fullPathId, valueOptimizeForQCScript as unknown] as FieldValue];
    }, []);
  }

  get formContextDico(): FormContextDico {
    return {
      fieldValue: new CaseInsensitiveMap<string, unknown>(this.fieldValue),
      fieldReadonlyFlag: new CaseInsensitiveMap<string, boolean>(),
      fieldVisibilityFlag: new CaseInsensitiveMap<string, boolean>(),
      warnings: [],
      informations: [],
    };
  }

  setFlattenFields = (flattenFields: FieldDesc[]): void => {
    this.flattenFields = flattenFields;
  };

  setFlattenFieldsByFullPathId = (fullPathId: string, nextValue: AllFieldValueTypes): void => {
    this.flattenFields.forEach((f: FieldDesc) => {
      if (f.fullPathId === fullPathId) f.value = nextValue;
    });
  };

  setControllerMethod = (method?: string): void => {
    this.controllerMethod = method ?? '';
  };

  setQCSBaseFieldId = (fullPathId: string): void => {
    this.qcsBaseFieldId = fullPathId;
  };

  setQCSFieldId = (fieldId: string): void => {
    this.qcsFieldId = fieldId;
  };

  setShouldAbortRequests = (shouldAbortRequests: boolean) => {
    this.shouldAbortRequests = shouldAbortRequests;
  };

  setIsDebugModeEnabled = (isDebugModeEnabled: boolean) => {
    this.isDebugModeEnabled = isDebugModeEnabled;
  };

  private _setHost = () => {
    if (this.qcscriptObj) {
      if (this.host) this.host.setHost(this);
      this.host = new QCSHost(this);
    }
  };

  private _setQCInterpreter = () => {
    if (this.host) this.qci = new QCSInterpreter(this.host);
  };

  public setToastId = (toastId: string | number | undefined): void => {
    this.toastId = toastId;
  };

  public getReadOnlyField = (fullPathId: string): boolean => {
    const field: FieldDesc | undefined = this.flattenFields.find((f: FieldDesc) => f.fullPathId === fullPathId);

    if (!field) return true;

    return field.fieldIsReadOnly ?? true;
  };

  isReadOnly = (): boolean => this._formIsReadOnly;

  public getOwnedFormContext(fullId: string): IQCSFormContext | null {
    // Trouver le champ correspondant
    const field = this.flattenFields.find(({ fullPathId }: FieldDesc) => fullId === fullPathId);

    if (!field || !this.host) return null;
    // Créer le formContext correspondant au champ et ainsi qu`a ses enfants par récusivité
    const fcOwned = new QCSFormContext(this.host);

    return fcOwned.initializeWithFieldDesc(field);
  }

  public getValue(sid: string): FieldDesc | undefined {
    return this.flattenFields.find(({ fullPathId }: FieldDesc) => fullPathId === sid);
  }

  public getFieldType(sid: string): FieldType {
    return this.flattenFields.find(({ fullPathId }: FieldDesc) => fullPathId === sid)?.fieldType ?? FieldType.Unknow;
  }

  public validate(sid: string, nextValueProposed: unknown): boolean {
    const field: FieldDesc | undefined = this.flattenFields.find((f) => f.fullPathId === sid);

    if (!field) return false;

    switch (field.fieldType) {
      case FieldType.Address:
        return isAddressData(nextValueProposed);

      case FieldType.CodeReader:
        return typeof nextValueProposed === 'string';

      case FieldType.Alert: {
        return (
          isAnAlertChoice(nextValueProposed) ||
          (typeof nextValueProposed === 'string' && isAlertValueStringFormat(nextValueProposed))
        );
      }

      case FieldType.Counter:
      case FieldType.Digits: {
        return NumberExtension.isAValidInteger(nextValueProposed);
      }

      case FieldType.RadioList:
      case FieldType.Combo: {
        const { listChoice } = field as ComboDesc;

        if (!listChoice) return false;

        return (
          isChoice(nextValueProposed) &&
          (hasValueForThisProperty(listChoice, 'value', nextValueProposed.value) ||
            hasValueForThisProperty(listChoice, 'label', nextValueProposed.label))
        );
      }

      case FieldType.Geolocation: {
        return !!isCoordinates(nextValueProposed);
      }

      case FieldType.HierarchicalList: {
        const { listChoice } = field as HierarchicalDesc;

        if (!listChoice) return false;

        return (
          isChoiceList(nextValueProposed) && findChoiceInHierarchicalChoice(listChoice, nextValueProposed).length > 0
        );
      }

      case FieldType.CheckBoxList: {
        const { listChoice } = field as CheckBoxListDesc;

        if (!listChoice) return false;

        return isChoiceList(nextValueProposed) && findChoiceInChoiceList(listChoice, nextValueProposed).length > 0;
      }

      case FieldType.Numeric:
      case FieldType.Slider: {
        return NumberExtension.isAValidNumber(nextValueProposed);
      }

      case FieldType.DateTime:
      case FieldType.Time:
        return DateTimeExtension.isAValidDateTime(nextValueProposed);

      case FieldType.Notification: {
        const isOk = !!isQCNotification(nextValueProposed);
        return isOk;
      }

      case FieldType.CheckBox:
        return typeof nextValueProposed === 'boolean';

      case FieldType.Text:
      case FieldType.ReadOnlyValue:
        return typeof nextValueProposed === 'string';

      default:
        return false;
    }
  }
}

export default QCScriptStore;
