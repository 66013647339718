import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  DECLARATION,
  NEW_DECLARATION,
  DECLARATION_FROM_INBOX,
  NEW_DECLARATION_WITH_EQUIPMENT,
  DECLARATIONS,
  EQUIPMENT,
  ERROR,
  FORMS,
  HOME,
  INBOXES,
  LOGIN,
  QRSCAN,
  LOGIN_ADAL,
  DECLARATION_HISTORY,
  USER_SETTING,
  DEEP_LINK,
  NEW_DECLARATION_FROM_DUPLICATION,
} from './paths';
import { LinkRouteType, MainRouteType } from './types';
import Declaration from '10.quickConnect.app/components/domain/Declaration';
import Forms from '10.quickConnect.app/components/domain/Home/Forms';
import Equipment from '10.quickConnect.app/components/domain/Equipment';
import Declarations from '10.quickConnect.app/components/domain/Home/Declarations';
import Inboxes from '10.quickConnect.app/components/domain/Home/Inboxes';
import Login from '10.quickConnect.app/components/domain/Login';
import QRCodeScan from '10.quickConnect.app/components/domain/QRCodeScan';
import Home from '10.quickConnect.app/components/domain/Home';
import LoginAdal from '10.quickConnect.app/components/domain/Login/LoginAdal';
import { HomeTabs } from '90.quickConnect.Models/enums';
import DeclarationHistory from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationHistory';
import UserSetting from '10.quickConnect.app/components/domain/Home/UserSetting';

const BaseROUTES: LinkRouteType[] = [
  { path: ERROR, key: 'ERROR', element: <Navigate to={ERROR} /> },
  { path: '*', key: 'NOT_FOUND', element: <Navigate to={HOME} /> },
];

export const ANONYMOUS_ROUTES: LinkRouteType[] = [
  ...BaseROUTES,
  { path: LOGIN, key: 'LOGIN', element: <Login /> },
  { path: LOGIN_ADAL, key: 'LOGIN_ADAL', element: <LoginAdal /> },
  { path: HOME, key: 'HOME', element: <Login /> },
];

// Routes affichées dans le menu de base
export const MAIN_PAGE_SUBROUTES: MainRouteType[] = [
  { path: QRSCAN, key: 'qrCode', element: <QRCodeScan />, homeTab: HomeTabs.QrScan },
  { path: FORMS, key: 'mainMenu_pages_forms', element: <Forms />, homeTab: HomeTabs.Forms },
  {
    path: DECLARATIONS,
    key: 'mainMenu_pages_declarations',
    element: <Declarations />,
    homeTab: HomeTabs.Declarations,
  },
  {
    path: INBOXES,
    key: 'mainMenu_pages_inboxes',
    element: <Inboxes />,
    homeTab: HomeTabs.Inboxes,
  },
];

export const USER_ROUTES: LinkRouteType[] = [
  ...BaseROUTES,

  { path: NEW_DECLARATION, key: 'NewDeclaration', element: <Declaration /> } as LinkRouteType,
  {
    path: NEW_DECLARATION_WITH_EQUIPMENT,
    key: 'NewDeclarationWithEquipment',
    element: <Declaration />,
  } as LinkRouteType,
  { path: DECLARATION, key: 'DeclarationWithMetaData', element: <Declaration /> } as LinkRouteType,
  { path: DECLARATION_FROM_INBOX, key: 'DeclarationWithInbox', element: <Declaration /> } as LinkRouteType,
  { path: EQUIPMENT, key: 'mainMenu.pages.equipment', element: <Equipment /> },
  { path: HOME, key: 'HOME', element: <Home />, children: MAIN_PAGE_SUBROUTES } as LinkRouteType,
  { path: DECLARATION_HISTORY, key: 'ancienneDeclaration', element: <DeclarationHistory /> } as LinkRouteType,
  { path: USER_SETTING, key: 'parametresUtilisateur', element: <UserSetting /> } as LinkRouteType,

  // Duplication Declaration
  {
    path: NEW_DECLARATION_FROM_DUPLICATION,
    key: 'DeclarationWithMetaDataDuplicated',
    element: <Declaration isDuplicated={true} />,
  } as LinkRouteType,
  // Deep Link
  { path: DEEP_LINK, key: 'deeplink', element: <Declaration isDeepLink={true} /> } as LinkRouteType,
];
