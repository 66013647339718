import styled from '@emotion/styled';
import { Accordion, Theme, AccordionSummary, alpha, AccordionDetails } from '@mui/material';

export const CustomAccordion = styled(Accordion)(({ expanded, theme }: { expanded: boolean; theme: Theme }) => ({
  width: '100%',
  border: `2px solid ${theme.palette.borderGroup.main}`,
  borderBottom: expanded ? `2px solid ${theme.palette.borderGroup.main}` : 'none',
  ' & .MuiPaper-elevation0': {
    borderRadius: '8px',
  },
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }: { theme: Theme }) => {
  const {
    palette: { borderGroup, backGroundGroup },
  } = theme;
  return {
    backgroundColor: alpha(backGroundGroup.main, 0.1),
    borderBottom: `2px solid ${borderGroup.main}`,
    fontSize: '1.1em',
    ' & .MuiPaper-elevation0': {
      borderRadius: '8px',
    },
  };
});

export const CustomAccordionDetails = styled(AccordionDetails)(({ theme }: { theme: Theme }) => {
  const {
    palette: { backGroundGroup },
  } = theme;
  return {
    backgroundColor: alpha(backGroundGroup.main, 0.05),
  };
});
