export const LOGIN = '/login';
export const LOGIN_ADAL = '/login/adal/';
export const HOME = '/';

// Home Tabs
export const FORMS = '/forms';
export const EQUIPMENTS = '/equipments';
export const INBOXES = '/inboxes';
export const EQUIPMENT = '/equipments/:schemaId/:entityId';
export const DECLARATIONS = '/declarations';
export const USER_SETTING = '/utilisateur';

// Others
export const QRSCAN = `/scan`;
export const FORM = `${FORMS}/:formId`;
export const NEW_DECLARATION = `${FORM}/declaration`;
export const NEW_DECLARATION_WITH_EQUIPMENT = `${FORM}/entity/:entityInstanceId`;
export const DECLARATION = `${FORM}/declaration/:declarationId`;
export const NEW_DECLARATION_FROM_DUPLICATION = `${DECLARATION}/duplicate`;
export const DECLARATION_FROM_INBOX = `${FORM}/inbox/:inboxId/:activityId`;
export const DECLARATION_HISTORY = `${DECLARATIONS}/anciennes`;

// Deep Link
export const DEEP_LINK = '/deeplink';

// Common
export const ERROR = '/error';
