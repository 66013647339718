import { IQCSHost } from '20.formLib/helpers/QCScriptLib/interfaces/IQCSHost';
import { QCScriptObj } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCScriptObj';
import { QCSModules } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSModules';
import { QCScriptFunctionInfo } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCScriptFunctionInfo';
import { QCSInstance } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInstance';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { Stack } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/stack';
import { Op } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/PCodeList';
import { CaseInsensitiveMap } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/CaseInsensitiveMap';
import { QCSInt } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInt';
import { QCSDouble } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDouble';
import { QCSString } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSString';
import { QCSBool } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBool';
import { KT } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/KnowTypesList';
import { QCSLong } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSLong';
import { QCSArray } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSArray';
import {
  FunctionTableDictionary,
  GlobalInitDoneDictionary,
} from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/types/QCSInterpreter';
import { IQCSFormContext } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/IQCSFormContext';
import { QCSInterpreterContext } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSInterpreterContext';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import i18n from '80.quickConnect.Core/utils/i18n';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class QCSInterpreter {
  private static readonly TAG =
    '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSInterpreter.ts';

  private readonly mTag = this.constructor.name;

  private _linkedObj: Array<QCScriptObj> = [];

  private _functionTable: FunctionTableDictionary;

  private _antiReEntrance: CaseInsensitiveMap<string, number>;

  private _modules: QCSModules;

  // Version supporté des QCScriptObj
  private _supportedVersion = 1;

  private static readonly _maxReEntrance = 10;

  private _host: IQCSHost;

  // Methode en cours d'interprétation
  private _currentMethodName = '';

  // Script courant
  private _scriptName = '';

  // Flag indiquant que les initialisations des variables et constantes globales ont été faites
  private _globalInitDone: GlobalInitDoneDictionary;

  public progStart = -1; // address of first instruction of main

  // public pc = -1; // program counter

  // Le code et la table des string en cours d'execution
  public currentObj: QCScriptObj = new QCScriptObj({});

  // data for Interpret
  private _MAX_STACK = 1023;

  public globals: QCSBaseObject[] = new Array<QCSBaseObject>(100);

  public stack: QCSBaseObject[] = new Array<QCSBaseObject>(this._MAX_STACK + 1);

  public switchStack: Stack<QCSBaseObject> = new Stack<QCSBaseObject>();

  public top = 0; // top of stack

  public bp: number; // base pointer

  private _maxErrorSupported: number;

  // Flag de debug pour activer les traces lors de l'interpretation
  private _debugMode = false;

  // Niveau de réentrance de la methode Interpret
  private _interpretting: number;

  private static readonly _mTag = 'QCSInterpreter';

  constructor(host: IQCSHost) {
    this._host = host;
    this._modules = new QCSModules();
    this._functionTable = new CaseInsensitiveMap();
    this._globalInitDone = new CaseInsensitiveMap();
    this._antiReEntrance = new CaseInsensitiveMap();
    this.bp = 0;
    this._maxErrorSupported = 25;
    this._interpretting = 0;
    this._debugMode = host.isDebugModeEnabled;
  }

  public link(obj: QCScriptObj): void {
    const lst: Array<QCScriptObj> = [obj];
    this.Link(lst);
  }

  public Link(objs: Array<QCScriptObj>) {
    // Ne pas charger les QCScriptObj dont la version est > _supportedVersion
    this._linkedObj = objs.filter((obj: QCScriptObj) => obj.versionCode <= this._supportedVersion);

    // Alimenter la table des fonctions
    objs.forEach((obj: QCScriptObj) => {
      const { functions } = obj;
      if (functions.length > 0) {
        const forThisMod: CaseInsensitiveMap<string, number> = new CaseInsensitiveMap();

        functions.forEach((fct: QCScriptFunctionInfo) => forThisMod.set(fct.name, fct.memoryAddress));

        this._functionTable.set(obj.scriptName, forThisMod);
      }
    });
  }

  getCurrentScriptName = (): string => this._currentMethodName;

  getScriptName = (): string => this._scriptName;

  /*
   * Appelée par l'appli pour lancer l'interprétation d'une methode
   * @param {string} methodName
   * @param toPush
   * @param allMethods
   * @return
   */
  callInterpreterMethod(
    methodName: string,
    fc: IQCSFormContext,
    fieldId?: string,
    onImageClickedFieldId?: string,
    allMethods = false,
  ): unknown {
    // eslint-disable-next-line
    try {
      // Setl'interpreteur dans le formContext
      fc.setScriptInfo(this);
      let result: unknown = null;

      // On prend en compte les erreurs levées pendant les précédentes executions. Si le nombre d'erreurs est trop important, on ne lancera plus l'interpréteur
      if (this._maxErrorSupported <= 0) {
        // Garde fou
        if (this._maxErrorSupported == 0) {
          this._maxErrorSupported--;
        }

        fc.showWarning([new QCSString(i18n.t('formlib_qcscript_too_many_error', { ns: 'declaration' }))]);

        return null;
      }

      // Recherche la méthode
      const methodsObj: QCScriptObj[] = [];
      const methodsAddress: number[] = [];
      const toFind = methodName;

      for (const obj of this._linkedObj) {
        const { scriptName } = obj;

        if (!scriptName) continue;

        const fcts: CaseInsensitiveMap<string, number> | undefined = this._functionTable.get(scriptName);

        if (fcts) {
          const adr = fcts.get(toFind);

          if (adr) {
            methodsObj.push(obj);
            this._currentMethodName = toFind;
            methodsAddress.push(adr);
            if (!allMethods) break;
          }
        }
      }

      // Non trouvée
      if (methodsObj.length === 0) {
        return;
      }

      // Gestion de l'anti-ReEntrance...
      const alreadyExecuting: number = this._antiReEntrance.get(toFind) ?? 0;

      if (alreadyExecuting > QCSInterpreter._maxReEntrance) {
        CustomLogger.getInstance().warn(QCSInterpreter.TAG, QCSInterpreter._mTag + 'Reentrance limit ' + toFind);
        this._antiReEntrance.delete(toFind);
        return null;
      }

      this._antiReEntrance.set(toFind, alreadyExecuting + 1);

      for (let i = 0; i < methodsObj.length; i++) {
        const methodObj = methodsObj[i];
        const methodAddress = methodsAddress[i];
        this._currentMethodName = methodObj.scriptName;

        // Vérifier que le module est initialisé avant d'appeler une méthode
        const globalInitDone: boolean = this._globalInitDone.get(methodObj.scriptName) ?? false;

        if (!globalInitDone) {
          this.globalInitFor(methodObj);
          this._globalInitDone.set(methodObj.scriptName, true);
        }

        // Ajoute les parametres dans la stack
        const toPush: Array<QCSBaseObject> = [];
        toPush.push(new QCSInstance(fc));
        if (fieldId) toPush.push(new QCSString(fieldId));
        if (onImageClickedFieldId) toPush.push(new QCSString(onImageClickedFieldId));

        // Lance l'interprétation
        const newIC = new QCSInterpreterContext(methodObj, toFind, methodAddress, this.top, this.bp);

        if (this._debugMode) {
          CustomLogger.getInstance().debug(
            QCSInterpreter.TAG,
            `${QCSInterpreter._mTag} Interpret ${newIC.currentMethodName} DEBUT`,
          );
        }

        this._interpretting += 1;

        const dummyResult = this.interpret(newIC, -1, toPush);

        //Garde le premier résultat arbitrairement
        if (result === null) {
          result = dummyResult;
        }

        this._interpretting -= 1;
        this.bp = newIC.getBackupBp();
        this.top = newIC.getBackupTop();
        if (this._debugMode) {
          CustomLogger.getInstance().debug(
            QCSInterpreter.TAG,
            `${QCSInterpreter._mTag} Interpret ${newIC.currentMethodName} FIN`,
          );
        }
      }

      this._antiReEntrance.set(toFind, alreadyExecuting);

      return result;
    } catch (error: any) {
      if (error instanceof Error) {
        error.message = `QCSCRIPT: ${fc.getFormName([]).getValue()} (${this.getCurrentScriptName()}): ${error.message}`;
        errorHandler(QCSInterpreter.TAG, error, 'callInterpreterMethod', 'error');
      }
      if (this._maxErrorSupported > 0) this._maxErrorSupported--;
      return null;
    }
  }

  // Vérifier si il faut executer du code d'initialisation global sur le module
  // Execute le code si besoin
  private globalInitFor(methodObj: QCScriptObj): void {
    const toPush: Array<QCSBaseObject> = [];

    // Le code d'initialisation est contenu au debut de l'obj jusqu'au premier PCode.ENTER
    const toMaxPCode: number = methodObj.endInitPCode;

    if (toMaxPCode <= 0) return;

    this._interpretting += 1;

    const newIC = new QCSInterpreterContext(methodObj, '__globalInit', 1, this.top, this.bp);

    // Lance l'interprétation du code d'init
    this.interpret(newIC, toMaxPCode, toPush);

    this.top = newIC.getBackupTop();
    this.bp = newIC.getBackupBp();
    this._interpretting -= 1;
  }

  public next(ic: QCSInterpreterContext): number {
    const result = ic.currentObj.pCode[ic.pc++];
    return result;
  }

  public next2(ic: QCSInterpreterContext) {
    const x: number = ic.currentObj.pCode[ic.pc++];
    const y: number = ic.currentObj.pCode[ic.pc++];
    return (x << 8) + y;
  }

  public int(b: boolean): number {
    if (b) return 1;
    else return 0;
  }

  public push(item: QCSBaseObject | number): void {
    if (this.top > this._MAX_STACK) throw new Error(`Stack overflow in ${this._currentMethodName}`);
    const index = this.top++;
    this.stack[index] = typeof item === 'number' ? new QCSInt(item) : item;
  }

  public pop(): QCSBaseObject {
    const index = this.top--;
    const result: QCSBaseObject | null = this.stack[this.top];
    this.stack[index] = new QCSBaseObject();
    return result;
  }

  // eslint max-lines-per-function: off
  private interpret(
    ic: QCSInterpreterContext,
    stopExecOnPc: number,
    toPush: Array<QCSBaseObject>,
  ): QCSBaseObject | null | never {
    let val: number;
    let qcsVal: QCSBaseObject;
    const whileCondition = true;
    const sbExec: string[] = [];

    this.bp = this.top;
    this.stack[0] = QCSBaseObject.QCSNull;
    // Forcer les parametres
    toPush.forEach((bo: QCSBaseObject) => {
      this.push(bo);
    });
    this.push(0);
    let retValue: QCSBaseObject | null = null;
    const nbParamFunction: Stack<number> = new Stack<number>();
    const originalBp = this.bp;

    while (whileCondition) {
      if (ic.pc === stopExecOnPc) {
        while (this.top > originalBp) {
          this.pop();
        }
        return retValue;
      }

      sbExec.push(`pc=${ic.pc} bp=${this.bp} top=${this.top}`);
      // sbExec.forEach((sbEx: string) => console.log(sbEx));

      const nextPCode: number = this.next(ic);

      // console.log(`pCode: ${Op[nextPCode]}`);

      switch (nextPCode) {
        case Op.CONST:
          this.push(this.next2(ic));
          break;

        case Op.LCONST:
          this.push(new QCSLong(ic.currentObj.constLong[this.next2(ic)]));
          break;

        case Op.SCONST:
          this.push(new QCSString(ic.currentObj.constString[this.next2(ic)]));
          break;

        case Op.DCONST:
          this.push(new QCSDouble(ic.currentObj.constDouble[this.next2(ic)]));
          break;

        case Op.ICONST:
          this.push(new QCSInt(ic.currentObj.constInt[this.next2(ic)]));
          break;

        case Op.LOAD:
          this.push(this.stack[this.bp + this.next2(ic)]);
          break;

        case Op.LOADG:
          this.push(this.globals[this.next2(ic)]);
          break;

        case Op.STO:
          this.stack[this.bp + this.next2(ic)] = this.pop();
          break;

        case Op.STOG:
          this.globals[this.next2(ic)] = this.pop();
          break;

        case Op.ADD:
          qcsVal = this.pop();
          this.push(this.pop().add(qcsVal));
          break;

        case Op.SUB:
          this.push(this.pop().negation().add(this.pop()));
          break;

        case Op.DIV:
          qcsVal = this.pop();
          this.push(this.pop().div(qcsVal));
          break;

        case Op.MOD:
          qcsVal = this.pop();
          this.push(this.pop().modulo(qcsVal));
          break;

        case Op.MUL:
          this.push(this.pop().multiplyBy(this.pop()));
          break;

        case Op.NEG:
          this.push(this.pop().negation());
          break;

        case Op.NOT:
          this.push(this.pop().isFalse() ? QCSBool.trueValue : QCSBool.falseValue);
          break;

        case Op.PLUSEQ:
          qcsVal = this.pop();
          this.push(qcsVal.add(this.pop()));
          break;

        case Op.SUBEQ:
          qcsVal = this.pop();
          this.push(this.pop().negation().add(qcsVal));
          break;

        case Op.MULEQ:
          qcsVal = this.pop();
          this.push(qcsVal.multiplyBy(this.pop()));
          break;

        case Op.DIVEQ:
          qcsVal = this.pop();
          this.push(qcsVal.div(this.pop()));
          break;

        case Op.EQU:
          this.push(this.pop().equals(this.pop()) ? QCSBool.trueValue : QCSBool.falseValue);
          break;

        case Op.NEQU:
          this.push(this.pop().equals(this.pop()) ? QCSBool.falseValue : QCSBool.trueValue);
          break;

        case Op.LSS:
          this.push(this.pop().greaterThan(this.pop()));
          break;

        case Op.GTR:
          this.push(this.pop().lessThan(this.pop()));
          break;

        case Op.LSSE:
          this.push(this.pop().greaterEqualThan(this.pop()));
          break;

        case Op.GTRE:
          this.push(this.pop().lessEqualThan(this.pop()));
          break;

        case Op.JMP:
          ic.pc = this.next2(ic);
          break;

        case Op.FJMP:
          val = this.next2(ic);
          if (this.pop().isFalse()) ic.pc = val;
          break;

        case Op.TJMP:
          val = this.next2(ic);
          if (!this.pop().isFalse()) ic.pc = val;
          break;

        case Op.CALL: // Faire un appel de méthode
          this.push(ic.pc + 2); // Sauvegarde l'adresse de retour
          ic.pc = this.next2(ic); // Déplace le pointer program à l'adresse de la méthode appelée
          break;

        case Op.CALLX:
          const nextpc: number = ic.pc + 3;
          this.callX(this.bp, this.next(ic), this.next(ic), this.next(ic));
          ic.pc = nextpc;
          break;

        case Op.RETURN: // Fixe le code retour de la fonction
          const withValue: number = this.next2(ic);
          if (withValue == 1) retValue = this.pop();
          break;

        case Op.RET: // Retour de fonction et repositionne le programme à l'instruction suivante de l'appel
          const tmpPc: QCSBaseObject = this.pop(); // Récupère l'adresse de retour
          const nbParamToPop: number = nbParamFunction.pop()!; // Récupère le nombre de paramètre à dépiler
          for (let i = 0; i < nbParamToPop; i++) this.pop();
          if (tmpPc == QCSBaseObject.QCSNull) return retValue; // Arret si pas d'adresse de retour
          ic.pc = (tmpPc as QCSInt).value; // Positionne le pc sur la prochain pcode à executer
          if (ic.pc === 0) return retValue;
          if (retValue != null) {
            // Push le précédent retour de méthode si besoin
            this.push(retValue);
            retValue = null;
          }
          break;

        case Op.ENTER: // Entrée dans une fonction
          this.push(this.bp); // Sauvegarde la base de la pile actuelle
          const nbParam: number = this.next(ic); // Récupère le nombre de parametre
          this.copyParam(nbParam, this.top); // Recopier les parametres dans notre nouvelle base
          nbParamFunction.push(nbParam); // Empile le nombre de parametre
          this.bp = this.top; // Nouvelle base de pile pour la nouvelle fonction
          this.top = this.top + this.next2(ic); // Réserve dans la pile l'espace pour les paramètres et les variables locales
          break;

        case Op.LEAVE: // Sortir de la fonction
          for (let i: number = this.top; i >= this.bp; i--) this.stack[i] = new QCSBaseObject();
          this.top = this.bp; // Vider la pile jusqu'à sa base pour que le depile de l'adresse de retourne fonctionne
          this.bp = (this.pop() as QCSInt).value; // Restore la base de la pile
          break;

        case Op.SSWTCH: // Enregistrer dans switchStack le résultat de l'expression d'un switch
          qcsVal = this.pop();
          this.switchStack.push(qcsVal);
          break;

        case Op.ESWTCH:
          this.switchStack.pop();
          break;

        case Op.CSWTCH:
          // Remettre dans la pile la valeur d'expression du switch courant
          this.push(this.switchStack.peek()!);
          break;

        case Op.ANEW: // Allocation d'un tableau
          this.push(this.createArray(this.next2(ic), this.next2(ic)));
          break;
        case Op.AINIT: // Initialisation d'un tableau
          this.push(this.initArray(this.next2(ic)));
          break;

        case Op.AINDEX: // Indexation d'un élément de tableau
          this.push(this.indexArray(this.next(ic)));
          break;

        case Op.TRUE:
          this.push(QCSBool.trueValue);
          break;

        case Op.FALSE:
          this.push(QCSBool.falseValue);
          break;

        case Op.NULL:
          this.push(QCSBaseObject.QCSNull);
          break;

        case Op.OR:
          const firstMemberOr: boolean = (this.pop() as QCSBool).value;
          const secondMemberOr: boolean = (this.pop() as QCSBool).value;
          this.push(new QCSBool(firstMemberOr || secondMemberOr));
          break;

        case Op.AND:
          const firstMemberAnd: boolean = (this.pop() as QCSBool).value;
          const secondMemberAnd: boolean = (this.pop() as QCSBool).value;
          this.push(new QCSBool(firstMemberAnd && secondMemberAnd));
          break;

        case Op.CONV:
          // const newQCSBaseObject: QCSBaseObject = this.autoConvert(this.pop(), this.next2());
          // this.push(newQCSBaseObject);
          this.push(this.autoConvert(this.pop(), this.next2(ic)));

          break;

        default:
          throw new Error('illegal opcode');
      }
    }
    return retValue;
  }

  /// Appelle une méthode externe au script
  /// C'est le host qui prend en charge
  private callX(bpForCall: number, moduleId: number, methodId: number, nbArgGiven: number): void {
    // Récupère les infos de la méthode à appeler
    const { isExist, mmi } = this._modules.getModuleMethodInfo(moduleId, methodId);
    if (!isExist) return;

    // Récupérer les parametres
    const qcParams: Array<QCSBaseObject> = [];
    for (let i = 0; i < nbArgGiven; i++) {
      qcParams.unshift(this.pop());
    }

    // Récupère l'instance si methode non statique
    let inst: QCSBaseObject | null = null;
    if (!mmi!.isStatic) {
      inst = this.pop();
    }

    // Verif nb param
    const nbParam: number = qcParams?.length ?? 0;
    if (nbParam >= mmi!.nbArg && nbParam <= mmi!.nbArg + mmi!.nbArgOpt) {
      // Demande au host d'executer la méthode

      if (this._debugMode) {
        const paramString = qcParams
          .map((bo: QCSBaseObject) => `${bo.constructor.name} : ${bo.getValue()}`, '')
          .join(', ');
        CustomLogger.getInstance().log(
          QCSInterpreter.TAG,
          `-> ${this.mTag} ${mmi?.methodName}, ${qcParams.length.toString()}, ${paramString}`,
        );
      }
      const result: QCSBaseObject | null = this._host.callExternal(mmi!, inst, qcParams);
      if (result != null) {
        if (this._debugMode)
          CustomLogger.getInstance().log(
            QCSInterpreter.TAG,
            `<- ${this.mTag} ${mmi?.methodName} return = ${result.getValue()}`,
          );
        this.push(result);
      }
    } else {
      throw new Error('modules.getArgumentOutOfRangeDetail');
    }
  }

  private copyParam(nbParam: number, currentTop: number): void {
    if (nbParam > 0) {
      for (let i = 0; i < nbParam; i++) {
        // Recopier dans la pile de l'appelé les paramètre push par l'appelant
        const topInCaller: number = currentTop - 2 - nbParam + i;
        this.stack[currentTop + i] = this.stack[topInCaller];
      }
    }
  }

  /**
   * Accéder à un élément de tableau
   *
   * @private
   * @param {number} nbDim
   * @return {*}  {QCSBaseObject}
   * @memberof QCSInterpreter
   */
  private indexArray(nbDim: number): QCSBaseObject {
    // Dépiler les NbDim index
    const dims: Array<QCSInt> = new Array<QCSInt>();
    for (let i = 0; i < nbDim; i++) {
      dims.push(this.pop() as QCSInt);
    }

    // Dépiler l'array
    const array: QCSArray = this.pop() as QCSArray;
    return array.getAt(dims);
  }

  private createArray(objType: number, nbDim: number): QCSBaseObject {
    const dims: Array<QCSInt> = [];
    for (let i = 0; i < nbDim; i++) {
      dims.push(this.pop() as QCSInt);
    }

    return QCSArray.create(objType, dims);
  }

  private initArray(nbItem: number): QCSBaseObject {
    const items: Array<QCSBaseObject> = [];
    for (let i = 0; i < nbItem; i++) {
      items.unshift(this.pop());
    }

    const arrayToInitialize: QCSArray = this.pop() as QCSArray;
    for (let i = 0; i < nbItem; i++) {
      const obj: QCSBaseObject = items[i];
      arrayToInitialize.store(i, obj);
    }

    return arrayToInitialize;
  }

  /**
   *Faire la conversion demandée
   *
   * @private
   * @param {QCSBaseObject} qcsBaseObject
   * @param {number} targetType
   * @return {*}  {QCSBaseObject}
   * @memberof QCSInterpreter
   */
  private autoConvert(qcsBaseObject: QCSBaseObject, targetType: KT): QCSBaseObject {
    switch (targetType) {
      case KT.KTInt:
        return QCSInt.from(qcsBaseObject);
      case KT.KTBool:
        return QCSBool.from(qcsBaseObject);
      case KT.KTDouble:
        return QCSDouble.from(qcsBaseObject);
      case KT.KTLong:
        return QCSLong.from(qcsBaseObject);
      case KT.KTString:
        return QCSString.from(qcsBaseObject);
      default:
        return QCSBaseObject.QCSNull;
    }
  }

  public get currentMethodName() {
    return this._currentMethodName;
  }
}
