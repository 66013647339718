import { Dispatch, SetStateAction } from 'react';
import { SignInRequest } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/requests';
import { ForgottenPasswordResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

export type PasswordRulesType = {
  passLength: boolean;
  lowerCase: boolean;
  upperCase: boolean;
  digit: boolean;
  specialCharacters: boolean;
};
export type UseDataProps = {
  signInRequest: SignInRequest;
  setUserUPN: (userUPN: string) => void;
  setPassword: (password: string) => void;
  isOpenBackdrop: boolean;
  setCgu: (cgu: boolean) => void;
  connect: () => Promise<void>;
  showPassword: boolean;
  setShowPassword: Dispatch<SetStateAction<boolean>>;
  isLogging: boolean;
  showPasswordField: boolean;
  forgetPassword: boolean;
  setForgetPassword: Dispatch<SetStateAction<boolean>>;
  onForgetPassword: () => Promise<void>;
  availableMethods: ForgottenPasswordResponse | undefined;
  method: ForgottenPasswordMethodEnum | undefined;
  setMethod: Dispatch<SetStateAction<ForgottenPasswordMethodEnum | undefined>>;
  onSelectMethod: () => Promise<void>;
  selectMethodPage: boolean;
  resetPasswordPage: boolean;
  onGoBackToLogin: () => void;
  onGoToResetPassword: () => void;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  confirmPassword: string;
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setResetCode: Dispatch<SetStateAction<string>>;
  resetCode: string;
  passwordRules: PasswordRulesType;
  resetPasswordData: ForgottenPasswordResponse | undefined;
  setPasswordRules: Dispatch<SetStateAction<PasswordRulesType>>;
  onResetPassword: () => void;
  resetCodeLoader: boolean;
};

export enum ForgottenPasswordMethodEnum {
  Unknown = 0, // L'utilisateur n'a pas encore choisi la méthode de réception de resetCode

  Email = 1, // L'utilisateur veut recevoir son resetCode par mail

  Sms = 2, // L'utilisateur veut recevoir son resetCode par sms

  Administrator = 4, // L'utilisateur veut que l'administrateur lui envoie son resetCode par mail

  Force = 99, // L'utilisateur à recu son resetCode et il est présent dans le champ resetCode
}
export enum ForgottenPasswordAvailableMethodsEnum {
  Email = 1 << 0, // L'utilisateur veut recevoir son resetCode par mail

  Sms = 1 << 1, // L'utilisateur veut recevoir son resetCode par sms

  EmailAndSms = Email | Sms, // L'utilisateur veut recevoir son resetCode par mail ou par sms

  Administrator = 1 << 2, // L'utilisateur veut que l'administrateur lui envoie son resetCode par mail
}

export type ForgottenPasswordAvailableMethod = {
  label: string;
  value: ForgottenPasswordAvailableMethodsEnum;
};
