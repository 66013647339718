import { v4 as uuidv4 } from 'uuid';
import mapGroupDesc from './mapGroupDesc';
import mapFieldDesc from './mapFieldDesc';
import { FieldDesc, RepeatableGroupDesc } from '90.quickConnect.Models/models';
import { ConsentFrequency, FieldType } from '90.quickConnect.Models/enums';

const mapRepeatableGroupDesc = (
  field: RepeatableGroupDesc,
  templateBodies: Record<string, string> | undefined | null,
): RepeatableGroupDesc => {
  const baseField = mapGroupDesc(field);
  const { isCollapsable, items, maxRow, nbRowOnInit } = field;
  const { id, fullPathId } = baseField;
  const newItems: FieldDesc[] = [];
  const groupTemplate: FieldDesc[] = items ? field.items.map((f) => mapFieldDesc(f, id, templateBodies)) : [];
  if (nbRowOnInit) {
    for (let i = 0; i < nbRowOnInit; i++) {
      const itemsFromGroupTemplate = groupTemplate.map((gt) => ({
        ...gt,
        fullPathId: fullPathId ? `${id}.${i}.${fullPathId}` : `${id}.${i}.${gt.id}`,
      }));
      const newIncludeField: FieldDesc = {
        stateId: uuidv4(),
        fieldType: FieldType.Include,
        items: itemsFromGroupTemplate,
        value: true,
        errors: undefined,
        fullPathId: `${fullPathId}.${i}`,
        id: `${id}.${i}`,
        fieldIsReadOnly: false,
        isVisible: true,
        checkRGPD: ConsentFrequency.Undef,
      };
      newItems.push(newIncludeField);
    }
  }
  return {
    ...baseField,
    isCollapsable: isCollapsable,
    groupTemplate: groupTemplate,
    maxRow: maxRow ?? 3,
    nbRowOnInit: nbRowOnInit ?? 0,
    items: newItems,
  } as RepeatableGroupDesc;
};

export default mapRepeatableGroupDesc;
