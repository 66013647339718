/*
 * Action effectué à une étape
 */
export enum WorkflowStepStatus {
  None = 0,
  /*
   * Etape validée par l'utilisateur
   */
  Validated = 2,
  /*
   * Etape annulée par l'utilisateur (soit par retour arriere, soit par abandon du wkf)
   */
  Canceled = 3,
}
